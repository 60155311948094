import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login_Screen from '../screens/Login_Screen';
import Home_Screen from '../screens/Home_Screen';
import Library_Screen from '../screens/Library_Screen';
import Logout_Screen from '../screens/Logout_Screen';
import Settings_Screen from '../screens/Settings_Screen';
import Document_Screen from '../screens/Document_Screen';
import ClassRooms_Screen from '../screens/ClassRooms_Screen';
import Camera_Screen from '../screens/Camera_Screen';
import Speech_Screen from '../screens/Speech_Screen';
import Q_A_Screen from '../screens/Q_A_Screen';
import Training_Test_Screen from '../screens/Training_Test_Screen';
import Training_Survey_Screen from '../screens/Training_Survey_Screen';
import Mindmap_Screen from '../screens/Mindmap_Screen'
import { React, useContext } from 'react';
import Guru_Medidation from '../screens/Guru_Medidation';
import Class_Screen from '../screens/Class_Screen';
import Folder_Screen from '../screens/Folder_Screen';
import Registration_Screen from '../screens/Registration_Screen';
import Registration_Professor_Screen from '../screens/Registration_Professor_Screen';
import UserContext from '../components/User_Context';
import Terms_Conditions_Screen from '../screens/Terms_Conditions_Screen';
import User_Profile_Screen from '../screens/User_Profile_Screen';
import Invitation_Screen from '../screens/Invitation_Screen';
import User_Subscription_Screen from '../screens/User_Subscription_Screen';
import Subscription_Catalogue_Screen from '../screens/Subscription_Catalogue_Screen';
import Checkout_Screen from '../screens/Checkout_Screen';
import User_Payments_Screen from '../screens/User_Payments_Screen';
import Privacy_Policy_Screen from '../screens/Privacy_Policy_Screen';
import Then_Training_Test_Screen from '../screens/Then_Training_Test_Screen';
import ContactUS_Screen from "../screens/ContactUS_Screen";
import Password_Reset_Screen from '../screens/Password_Reset_Screen';
import Language_Assistant_Screen from '../screens/Language_Assistant_Screen';

function Site_Routing(props) {

    const { user } = useContext(UserContext);
    if (user) {
        return (
            <Router>
                <Routes>
                    <Route
                        path="/library"
                        element={<Library_Screen />}
                    />
                    <Route
                        path="/privacy_policy"
                        element={<Privacy_Policy_Screen />}
                    />
                    <Route
                        path="/professor/library/classroom/:professorclassroomId"
                        element={<Library_Screen />}
                    />
                    <Route
                        path="/library/classroom/:classroomId"
                        element={<Library_Screen />}
                    />
                    <Route
                        path="/login"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/login/:provider/:loginId"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/logout"
                        element={<Logout_Screen />}
                    />
                    <Route
                        path="/"
                        element={<Home_Screen />}
                    />
                    <Route
                        path="/settings"
                        element={<Settings_Screen />}
                    />
                    <Route
                        path="/document/:id/?:page?"
                        element={<Document_Screen />}
                    />
                    <Route
                        path="/professor/document/classroom/:id/:professorclassroomId"
                        element={<Document_Screen />}
                    />
                    <Route
                        path="/document/classroom/:id/:classroomId"
                        element={<Document_Screen />}
                    />
                    <Route
                        path="/train/:id"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/folder/:folderId/train"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="professor/folder/:folderId/:professorclassroomId/train"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="classroom/folder/:folderId/:classroomId/train"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/folder/:folderId/:professorClassroom/train"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/train/folder/:folderId/:classroom"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/train/:id/:classroom"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/professor/train/:id/:professorclassroomId"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/classroom/train/:id/:classroomId"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/classroom/train/folder/:id/:classroomId"
                        element={<Training_Test_Screen />}
                    />
                    <Route
                        path="/survey/:id/:documentId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/professor/survey/:id/:documentId/:professorclassroomId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/survey/folder/:id/:folderId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/professor/survey/folder/:id/:folderId/:professorclassroomId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/classroom/survey/:id/:documentId/:classroomId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/classroom/survey/folder/:id/:folderId/:classroomId"
                        element={<Training_Survey_Screen />}
                    />
                    <Route
                        path="/qa/:documentId/:classroomId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/professor/qa/:documentId/:professorclassroomId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/classroom/qa/:documentId/:classroomId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/professor/qa/folder/:folderId/:professorclassroomId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/classroom/qa/folder/:folderId/:classroomId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/qa/:documentId"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/folder/:folderId/qa"
                        element={<Q_A_Screen />}
                    />
                    <Route
                        path="/classroom/mindmap/:id/:classroomId/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/professor/mindmap/:id/:professorclassroomId/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/mindmap/:id/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/folder/:folderId/mindmap/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/professor/folder/mindmap/:folderId/:professorclassroomId/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/classroom/folder/mindmap/:folderId/:classroomId/:topic?"
                        element={<Mindmap_Screen />}
                    />
                    <Route
                        path="/Guru_meditation"
                        element={<Guru_Medidation />}
                    />
                    <Route
                        path="/folder/:folderId"
                        element={<Folder_Screen />}
                    />
                    <Route
                        path="/professor/folder/classroom/:folderId/:professorclassroomId"
                        element={<Folder_Screen />}
                    />
                    <Route
                        path="/folder/classroom/:folderId/:classroomId"
                        element={<Folder_Screen />}
                    />
                    <Route
                        path="*"
                        element={<Home_Screen />}
                    />
                    <Route
                        path="/professor/classroom/:professorClassRoomId"
                        element={<Class_Screen />}
                    />
                    <Route
                        path="/register"
                        element={<Registration_Screen />}
                    />
                    <Route
                        path="/register_professor"
                        element={<Registration_Professor_Screen />}
                    />
                    <Route
                        path="/:professor/classrooms"
                        element={<ClassRooms_Screen />}
                    />
                    <Route
                        path="/classrooms"
                        element={<ClassRooms_Screen />}
                    />
                    <Route
                        path="/camera"
                        element={<Camera_Screen />}
                    />
                    <Route
                        path="/speech"
                        element={<Speech_Screen />}
                    />
                    <Route
                        path="professor/classroom/:professorClassRoomId/speech"
                        element={<Speech_Screen />}
                    />
                    <Route
                        path="/terms_and_conditions"
                        element={<Terms_Conditions_Screen />}
                    />
                    <Route
                        path="/invitation/:classroomId"
                        element={<Invitation_Screen />}
                    />
                    <Route
                        path="/user_profile"
                        element={<User_Profile_Screen />}
                    />
                    <Route
                        path="/user_subscription"
                        element={<User_Subscription_Screen />}
                    />
                    <Route
                        path="/catalogue"
                        element={<Subscription_Catalogue_Screen />}
                    />
                    <Route
                        path="/checkout/:profileCode"
                        element={<Checkout_Screen />}
                    />
                    {/* in progress routes */}
                    <Route
                        path="/training/:id"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/training/:id"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/folder/:folderId/training"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="professor/folder/:folderId/:professorclassroomId/training"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="classroom/folder/:folderId/:classroomId/training"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/folder/:folderId/:professorClassroom/training"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/training/folder/:folderId/:classroom"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/training/:id/:classroom"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/professor/training/:id/:professorclassroomId"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/classroom/training/:id/:classroomId"
                        element={<Then_Training_Test_Screen />}
                    />
                    <Route
                        path="/classroom/training/folder/:id/:classroomId"
                        element={<Then_Training_Test_Screen />}
                    />
                    {/* in progress routes above */}
                    <Route
                        path="/user_payments"
                        element={<User_Payments_Screen />}
                    />
                    <Route
                        path="/contactUs"
                        element={<ContactUS_Screen />}
                    />
                    <Route
                        path="/translate"
                        element={<Language_Assistant_Screen />}
                    />
                </Routes>
                {props.children}
            </Router>
        );
    }
    else {
        return (
            <Router>
                <Routes>
                    <Route
                        path="/terms_and_conditions"
                        element={<Terms_Conditions_Screen />}
                    />
                    <Route
                        path="/passwordReset"
                        element={<Password_Reset_Screen />}
                    />
                    <Route
                        path="/passwordReset/:randomCode"
                        element={<Password_Reset_Screen />}
                    />
                    <Route
                        path="/"
                        element={<Home_Screen />}
                    />
                    <Route
                        path="/login"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/login/:profileCode"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/Guru_meditation"
                        element={<Guru_Medidation />}
                    />
                    <Route
                        path="/login/:provider/:loginId"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/register"
                        element={<Registration_Screen />}
                    />
                    <Route
                        path="/register/:profileCode"
                        element={<Registration_Screen />}
                    />
                    <Route
                        path="/register_professor"
                        element={<Registration_Professor_Screen />}
                    />
                    <Route
                        path="*"
                        element={<Login_Screen />}
                    />
                    <Route
                        path="/catalogue"
                        element={<Subscription_Catalogue_Screen />}
                    />
                    <Route
                        path="/privacy_policy"
                        element={<Privacy_Policy_Screen />}
                    />
                    <Route
                        path="/contactUs"
                        element={<ContactUS_Screen />}
                    />
                </Routes>
            </Router>
        );
    }


}

export default Site_Routing;