import '../App.css';
import { useContext } from "react";
import UserContext from '../components/User_Context';
import { Navigate } from 'react-router-dom';
import * as api from '../api/apiConnector';
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as labels from "../components/localized/labels";
import { SetMenu } from '../components/Navigation';
import { useNavigate, useParams } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons, usePayPalScriptReducer, getScriptID, destroySDKScript } from "@paypal/react-paypal-js";
import { Col, Form, InputGroup } from 'react-bootstrap';
import { useCookies } from 'react-cookie';
import TagManager from "react-gtm-module";

function Checkout_Screen() {
    const [cookiesThirdParty, setThirdPartyCookie] = useCookies(localStorage.getItem("thirdPartyCookies"));

    const navigate = useNavigate();
    const { profileCode } = useParams();
    const [profile, setProfile] = useState([]);
    const [promoCode, setPromoCode] = useState("");
    const [price, setPrice] = useState(null);
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;
    const userLanguage = user?._language || labels.getLanguage();
    const [message, setMessage] = useState("");
    const [messagePromo, setMessagePromo] = useState("");
    let cid = process.env.REACT_APP_PAYPAL_CLIENT_ID;
    const SCRIPT_PROVIDER_OPTIONS = {
        "client-id": cid,
        intent: "subscription",
        currency: "EUR",
        vault: true,
    };

    // Renders errors or successfull transactions on the screen.
    function Message({ content, styles }) {
        return <p style={{ ...styles }}>{content}</p>;
    }

    const styles = {
        shape: "rect",
        layout: "vertical",
    };

    const stylesPromo = {
        color: "red",
        display: "inline",
    };

    async function applyPromoCode() {
        setMessagePromo("");
        const result = await api.sendMessage("applyPromoCode", {
            body: {
                promoCode: promoCode,
                language: userLanguage,
                subscriptionCode: profileCode,
                user: user,
            },
            user: user,
        });
        if (result.success) {
            const cardPriceElement = document.querySelector('.cardPrice');
            cardPriceElement.innerHTML = `€${result.price}`;
            setPrice(result.price);
            withReactContent(Swal).fire({
                title: <i>Success</i>,
                icon: "success",
                text: result.message,
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 3000,
            });
        } else {
            setMessagePromo(result.message);
        }
    }

    const getProfile = async () => {
        const result = await api.sendMessage("getProfileFromCode", {
            body: {
                code: profileCode,
                language: userLanguage,
                user: user,
            },
            user: user,
        });

        if (result.success) {
            setProfile(result.subscriptionProfile);
        }
    }
    useEffect(() => {
        getProfile();
    }, []); // Only runs once, on mount

    const ResetPayPalScript = () => {
        const [dispatch] = usePayPalScriptReducer();
        destroySDKScript(getScriptID(SCRIPT_PROVIDER_OPTIONS)); // Assumes these functions are imported
        dispatch({
            type: "setLoadingStatus",
            value: "initial",
            intent: "subscription",
        });
    };

    const createSubscription = async (profile) => {
        try {
            if (profile.planId !== null && profile.currency_code !== null) {
                const result = await api.sendMessage("createSubscription", {
                    body: {
                        plan_id: profile.planId,
                        currency_code: profile.currency_code
                    },
                    user: user,
                });
                const subscriptionData = await result.subscription;

                if (subscriptionData.id) {
                    return subscriptionData.id;
                } else {
                    const errorDetail = subscriptionData?.details?.[0];
                    const errorMessage = errorDetail
                        ? `${errorDetail.issue} ${errorDetail.description} (${subscriptionData.debug_id})`
                        : JSON.stringify(subscriptionData);

                    throw new Error(errorMessage);
                }


            }
        } catch (error) {
            setMessage(labels.getText()["PAYPAL_CHECKOUT_ERROR"]());
        }


    }

    const onApprove = (data, actions) => {
        setMessage(labels.getText()["SUBSCRIPTION_IN_PROGRESS"]());
        return actions.subscription.get().then((details) => {
            saveSubscriptionInfo(details).then((result) => {
                if (result.success) {

                    if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

                        // send custom event to GTM for initiate registration
                        try {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: "Purchase",
                                    // category: "category",
                                    action: "action",
                                    // label: "label",
                                    // value: "value",
                                },
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }

                    withReactContent(Swal).fire({
                        title: labels.getText()["SUBSCRIPTION_SUCCESSFUL"](),
                        icon: 'success',
                        timer: 1000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        showCloseButton: false
                    }).then(() => {
                        navigate('/user_subscription');
                    });
                } else {
                    Swal.fire({
                        title: labels.getText()["SUBSCRIPTION_ERROR"](),
                        icon: 'error',
                        timer: 1000,
                        showConfirmButton: false,
                        allowOutsideClick: false,
                        showCloseButton: false
                    });
                }
            });
        })
            .catch((error) => {
                console.error("Error during subscription capture", error);
            });

    }

    const saveSubscriptionInfo = async (data) => {
        return (await api.sendMessage("saveSubscriptionInfo", {
            body: {
                data: data,
                code: profileCode,
            },
            user: user,
        }));
    }

    if (user && profile) {
        return (
            <div className="App wrap">
                {SetMenu({ user: user })}
                <div className="container py-5 h-100">
                    <h1 className="display-4 mb-4 animated slideInLeft">
                        {labels.getText()["PROCEED_CHECKOUT"]()}
                    </h1>
                    <div className="container mt-5">
                        <Form.Group as={Col} sm={3} className="mt-3 mx-auto" controlId="PROMOCODE">
                            <InputGroup className="mb-3">
                                <Form.Control
                                    placeholder="PROMO CODE"
                                    aria-label="PROMO CODE"
                                    value={promoCode}
                                    onChange={(e) => setPromoCode(e.target.value)}
                                />
                                <button className="pagination-button" id="promoButton" onClick={applyPromoCode}>
                                    USE
                                </button>
                            </InputGroup>
                            <Message styles={stylesPromo} content={messagePromo} />
                        </Form.Group>
                        <div className="card">
                            <div className="card-body">
                                <span>
                                    <span className="cardPrice">€{price ? price : profile.price}</span>
                                    <span className='cardPriceTime'>/{profile.expirationValue > 1 ? `${profile.expirationValue}  ${labels.getText()["DURATION_SUB_MONTHLY_PLURAL"]({ expirationUnit: profile.expirationUnit })}` : labels.getText()["DURATION_SUB"]({ expirationUnit: profile.expirationUnit })}</span>
                                </span><h2 className="cardTitle">
                                    {profile?.name
                                        ? Array.isArray(profile.name)
                                            ? profile.name.find(item => item.iso === userLanguage.toUpperCase())?.text || labels.getText()["LANGUAGE_NOT_FOUND"]()
                                            : profile.name || labels.getText()["LOADING"]()
                                        : labels.getText()["LOADING"]()}</h2>
                                <p className="cardDescription text-muted" dangerouslySetInnerHTML={{
                                    __html: profile?.description
                                        ? Array.isArray(profile.description)
                                            ? profile.description.find(item => item.iso === userLanguage.toUpperCase())?.text || labels.getText()["LANGUAGE_NOT_FOUND"]()
                                            : profile.description || labels.getText()["LOADING"]()
                                        : labels.getText()["LOADING"]()
                                }}></p>
                                <div className="payment-method">
                                    <h4 className='mt-3'>{labels.getText()["SELECT_METHOD"]()}</h4>
                                </div>
                                {
                                    profile.planId && profile.currency_code && (
                                        <>
                                            <PayPalScriptProvider styles={styles} options={SCRIPT_PROVIDER_OPTIONS} deferLoading={false}>
                                                <PayPalButtons
                                                    createSubscription={() => createSubscription(profile)}
                                                    onApprove={onApprove}
                                                    onError={(error) => {
                                                        setMessage(`An error occurred: ${error}`);
                                                        ResetPayPalScript();
                                                    }
                                                    }
                                                />
                                            </PayPalScriptProvider>
                                            <Message content={message} />
                                        </>
                                    )
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    else {
        SetMenu({ user: user });
        return (<Navigate to="/" />);
    }

}

export default Checkout_Screen;