import '../App.css';
import UserContext from '../components/User_Context';
import { React, useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import * as api from '../api/apiConnector';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import Question_Packages_List from '../components/Question_Packages_List';
import { Survey_Widget } from '../components/Survey_Widget';
import { FileDownload } from '@mui/icons-material';
import * as labels from "../components/localized/labels";

import { getBreadcrumb, SetMenu, } from '../components/Navigation';

function Training_Survey_Screen() {
    const [_document, setDocument] = useState(null);
    const [questionPackage, setQuestionPackage] = useState(null);
    const [folder, setFolder] = useState(null);
    const [isDataLoaded, setIsDataLoaded] = useState(false);


    let { user } = useContext(UserContext);
    const { id, documentId, folderId, professorclassroomId, classroomId } = useParams();

    user = user ? JSON.parse(user) : null;
    { SetMenu({ user: user }) }
    const getDoc = async () => {
        return (await api.sendMessage("getDoc", {
            body: {
                id: documentId,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).document;
    }

    const getFolder = async () => {
        return (await api.sendMessage("getFolder", {
            body: {
                id: folderId,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).folder;
    }

    const getPackage = async () => {
        return (await api.sendMessage("getQuestionsPackage", {
            body: {
                id: id,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).questionsPackage;
    }


    useEffect(() => {
        const fetchData = async () => {
            try {
                const _book = await getDoc();
                const _folder = await getFolder();
                const _package = await getPackage();

                setDocument(_book);
                setFolder(_folder);
                setQuestionPackage(_package);

                setIsDataLoaded(true);  // Set data loaded state to true after all data is fetched
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    const download = async (response) => {
        try {
            /*const response = await api.downloadFile("downloadPdf", {
                body: {
                    questionsPackage: questions
                },
                user: user
            });*/
            console.log(response);
            if (response) {

                const fileUrl = URL.createObjectURL(response);
                const link = document.createElement('a');
                link.href = fileUrl;
                link.target = '_blank';
                link.click();
            } else {
                console.error('Error downloading PDF:', response.error);
            }
        } catch (error) {
            console.error('Error downloading PDF:', error);
        }
    }


    if (!isDataLoaded) {
        return (
            <div className="App App-body">
                <p>{labels.getText()["WAITING_FOR_DATA"]()}</p>
            </div>
        )
    }
    else {
        return (<div className="App App-body">
            <h1>
                {documentId ? (document.title ? document.title : document.fileName) : folder.name}
                <hr />
            </h1>
            {SetMenu({ user: user })}
            {documentId ?
                (getBreadcrumb({ page: "survey", id: documentId, id2: questionPackage.generationTime, name: _document.fileName, topicId: questionPackage._id,classroomId:classroomId,professorclassroomId:professorclassroomId })) :
                (getBreadcrumb({ page: "survey_folder", folderId: folderId, packageId: id,  name: folder.name }))}
            <div>
                <div >
                    <Survey_Widget
                        classroom={professorclassroomId || classroomId}
                        questionsPackage={questionPackage}
                        onSubmitted={(questionsPackage) => {
                            api.sendMessage("addQuestionPackageResult", {
                                body: {
                                    questionsPackage: questionsPackage,
                                },
                                user: user,
                            })
                        }}
                        onDownload={async (questions, submitted) => {
                            download(await api.downloadFile("downloadPdf", {
                                body: {
                                    questionsPackage: questions,
                                    submitted: submitted
                                },
                                user: user
                            }));
                        }}
                        onDownloadOpen={async (questions) => {
                            download(await api.downloadFile("downloadPdfOpen", {
                                body: {
                                    questionsPackage: questions
                                },
                                user: user
                            }));
                        }}
                    ></Survey_Widget>
                </div>
            </div>
        </div>);
    }

}

export default Training_Survey_Screen;
