import '../App.css';
import { useContext } from "react";
import UserContext from '../components/User_Context';
import * as api from '../api/apiConnector';
import React, { useState, useEffect } from 'react';
import * as labels from "../components/localized/labels";
import { SetMenu } from '../components/Navigation';
import { useNavigate } from 'react-router-dom';
import Carousel from "react-multi-carousel";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import "react-multi-carousel/lib/styles.css";

function User_Profile_Screen() {
  const [carouselData, setCarouselData] = useState('month');
  const navigate = useNavigate();
  var [profile, setProfile] = useState([]);
  const [userSubscription, setUserSubscription] = useState([]);
  let { user } = useContext(UserContext);
  user = user ? JSON.parse(user) : null;
  const responsive = {
    superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 3 },
    desktop: { breakpoint: { max: 3000, min: 1420 }, items: 3 },
    smallDesktop: { breakpoint: { max: 1420, min: 1024 }, items: 2 },
    tablet: { breakpoint: { max: 1024, min: 10 }, items: 1 },
  };

  function filterOutByCode(data, code) {
    const filteredData = {};

    // Loop over each key in the object
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        // Filter the array to exclude items with the specified title
        filteredData[key] = data[key].filter(plan => plan.code !== code);
      }
    }

    return filteredData;
  }

  async function getSubscription() {
    var result = await api.sendMessage("getSubscriptionProfiles", {
    });

    if (user) {
      var subscription = (await api.sendMessage("getUserSubscription", {
        user: user
      }));
      if (subscription.success) {
        if (subscription.nonexist) {

        }
        else {
          setUserSubscription(subscription.subscriptionProfile);
        }
      }

      if (result.success && subscription.success && subscription.subscriptionProfile) {
        const filteredData = filterOutByCode(result.subscriptionProfiles, subscription.subscriptionProfile.code);
        setProfile(filteredData);
      } else {
        setProfile(result.subscriptionProfiles);
      }
    } else {
      if (result.success) {
        setProfile(result.subscriptionProfiles);

      }
    }

  }

  useEffect(() => {
    (async () => {
      await getSubscription();
    })();
  }, []);


  const renderItems = (items) => {
    if (!items || !Array.isArray(items)) {
      return <p>{labels.getText()["NO_DATA_AVAILABLE"]()}</p>;
    }

    return items.map((item, index) => {
      // retrieving the language
      const userLanguage = user?._language || labels.getLanguage();

      const getLocalizedText = (array) => {
        if (Array.isArray(array)) {
          const textObj = array.find(t => t.iso === userLanguage.toUpperCase()) || array[0];
          return textObj ? textObj.text : '';
        }
        return array || ''; // Fallback if not an array
      };

      const titleText = getLocalizedText(item.title);
      const descriptionText = getLocalizedText(item.description);

      return (
        <div className="subscriptionCardContainer" key={index}>

          {item.badge && (
            <div className="badge bg-success text-white position-absolute" style={{ top: '10px', right: '10px' }}>
              SOLD OUT
            </div>
          )}
          <div className="subscriptionCardBody">
            <div className='cardTopContainer'>
              <div className='cardPriceContainer'>
                <span className='cardPrice'>€{item.price}</span>
                <span className='cardPriceTime'>/ {item.expirationValue > 1 ? `${item.expirationValue}  ${labels.getText()["DURATION_SUB_MONTHLY_PLURAL"]({ expirationUnit: carouselData })}` : labels.getText()["DURATION_SUB"]({ expirationUnit: carouselData })}</span>
              </div>
              <h2 className='cardTitle' dangerouslySetInnerHTML={{ __html: getLocalizedText(titleText) }}></h2>
              <p className='cardDescription' dangerouslySetInnerHTML={{ __html: getLocalizedText(descriptionText) }}></p>
            </div>
            {item.code && (
              <>
                <button className="subscriptionButton" onClick={e => {
                  if (!user) {
                    withReactContent(Swal).fire({
                      title: <i>{labels.getText()["LOGIN_PROCEED"]()}</i>,
                      icon: "info",
                      html: (
                        <div style={{ overflowX: 'hidden' }}>
                          <a
                            className="btn btn-primary py-sm-3 px-sm-5 rounded-pill animated slideInRight"
                            href={`/login/${item.code}`}

                          >
                            {labels.getText()["LOGIN"]()}
                          </a>
                          <p className="mt-2 animated slideInRight"><strong>{labels.getText()["DONT_HAVE_ACC"]()}</strong> </p>
                          <a
                            className="btn btn-primary py-sm-3 px-sm-5 rounded-pill animated slideInRight"
                            style={{ marginLeft: "2%" }}
                            href={`/register/${item.code}`}

                          >
                            {labels.getText()["DONT_HAVE_ACC2"]()}</a>
                        </div>
                      ),

                      showConfirmButton: false,
                      allowOutsideClick: true,
                      showCloseButton: true,

                    })

                  }
                  else if (user) {
                    navigate(`/checkout/${item.code}`);
                  }
                }}>{labels.getText()["ORDER"]()}</button>
                <ul className="list-unstyled">
                  {item.features && item.features.map((feature, i) => (
                    <li key={i} className="text-left">
                      <span className="text-success">&#10003;</span> {feature}
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>
      );
    });
  };
  const handleButtonClick = (type) => {
    setCarouselData(type);
  };

  // Choose the data to display based on the current state
  const plans = profile[carouselData] || [];
  return (
    <div className="App">
      {SetMenu({ user: user })}
      <div className="container subscriptionsSection py-5 h-100">
        <h1 className="display-4 mb-4 animated slideInLeft">
          {labels.getText()["CATALOGUE_HEADER"]()}
        </h1>
        <div className="subscriptionsContainer">
          <div className="tabContainer animated slideInRight">
            {
              profile.day && profile.day.length > 0 && (
                <button className="btn btn-outline-primary me-2" onClick={() => handleButtonClick('day')}>{labels.getText()["DAILY"]()}</button>
              )
            }
            {
              profile.week && profile.week.length > 0 && (
                <button className="btn btn-outline-primary me-2" onClick={() => handleButtonClick('week')}>{labels.getText()["WEEKLY"]()}</button>
              )
            }
            {
              profile.month && profile.month.length > 0 && (
                <button className="btn btn-outline-primary me-2" onClick={() => handleButtonClick('month')}>{labels.getText()["MONTHLY"]()}</button>
              )
            }
            {
              profile.year && profile.year.length > 0 && (
                <button className="btn btn-outline-primary" onClick={() => handleButtonClick('year')}>{labels.getText()["YEARLY"]()}</button>
              )
            }
          </div>
        </div>
        <div className="carouselContainer mt-5">
          <Carousel
            className="animated slideInRight"
            swipeable={false}
            draggable={false}
            showDots={true}
            responsive={responsive}
            ssr={true}
            infinite={true}
            autoPlaySpeed={1000}
            keyBoardControl={true}
            customTransition="all .5"
            transitionDuration={500}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
          >
            {renderItems(plans)}
          </Carousel>
        </div>
      </div>
    </div>
  );

}

export default User_Profile_Screen;