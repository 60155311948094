import UserContext from '../components/User_Context';
import LibraryScreen from './Library_Screen';
import { useSearchParams } from 'react-router-dom';
import React, { useContext } from 'react';
import LandingScreen from './Landing_Screen';

function Home_Screen() {
  let { user } = useContext(UserContext);
  user = user ? JSON.parse(user) : null;
  const [searchParams, setSearchParams] = useSearchParams();
  const { setLanguage } = useContext(UserContext);
  if (searchParams.get("language") && (searchParams.get("language") === "en" || searchParams.get("language") === "lt" || searchParams.get("language") === "it"
  || searchParams.get("language") === "es")) {
    localStorage.setItem("active_language", searchParams.get("language"));
    setLanguage(searchParams.get("language"));
  }
  if (!user) {
    //not logged users
    return (<LandingScreen></LandingScreen>);
  }
  else {
    //otherwise start with the library
    return (<LibraryScreen></LibraryScreen>)
  }

}

export default Home_Screen;
