import React, { useState, useEffect, useRef } from 'react';
import styles from '../../../styles.module.css';
import * as labels from "../../localized/labels";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Recorder = ({
  audioURL,
  showUIAudio,
  handleAudioStop,
  handleAudioUpload,
  audioFile,
  mimeTypeToUseWhenRecording,
  fileName
}) => {
  const [time, setTime] = useState({});
  const [miliseconds, setMiliseconds] = useState(0);
  const [recording, setRecording] = useState(false);
  const [mediaNotFound, setMediaNotFound] = useState(false);
  const [audios, setAudios] = useState([]);
  const [audioBlob, setAudioBlob] = useState(null);
  const timer = useRef(null);
  const mediaRecorder = useRef(null);
  const chunks = useRef([]);
  const stream = useRef(null);

  const startTimer = () => {
    timer.current = setInterval(() => countDown(), 100);
  };

  const countDown = () => {
    setMiliseconds((prevMiliseconds) => {
      const newMiliseconds = prevMiliseconds + 100;
      setTime(milisecondsToTime(newMiliseconds));
      return newMiliseconds;
    });
  };

  const milisecondsToTime = (milisecs) => {
    let secs = milisecs / 1000;
    let hours = Math.floor(secs / (60 * 60));

    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    let obj = {
      h: hours,
      m: minutes,
      s: seconds,
      ms: milisecs
    };
    return obj;
  };

  const initRecorder = async () => {
    navigator.getUserMedia =
      navigator.getUserMedia ||
      navigator.webkitGetUserMedia ||
      navigator.mozGetUserMedia ||
      navigator.msGetUserMedia;

    if (navigator.mediaDevices) {
      try {
        const streamData = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        if (mimeTypeToUseWhenRecording) {
          mediaRecorder.current = new MediaRecorder(streamData, {
            mimeType: mimeTypeToUseWhenRecording,
          });
        } else {
          mediaRecorder.current = new MediaRecorder(streamData);
        }
        chunks.current = [];
  
        mediaRecorder.current.addEventListener('dataavailable', async (e) => {
          if (e.data && e.data.size > 0) {
            chunks.current.push(e.data);
          }
        });
        stream.current = streamData;
      } catch (error) {
        withReactContent(Swal).fire({
          title: <i>Error</i>,
          text: "Error while accessing the microphone",
          icon: "error",
          timer: 1000,
          showConfirmButton: false,
          allowOutsideClick: false,
          showCloseButton: false
      });
      }
      
    } else {
      setMediaNotFound(true);
      console.log("Media Devices will work only with SSL.....");
    }
  };

  const startRecording = async (e) => {
    e.preventDefault();
    chunks.current = [];

    await initRecorder();
    if (!mediaRecorder.current) {
      return;
    }
    mediaRecorder.current.start(10);
    startTimer();
    setRecording(true);
  };

  const stopRecording = (e) => {
    e.preventDefault();
    clearInterval(timer.current);
    setTime({});
    if (stream.current && stream.current.getAudioTracks) {
      const tracks = stream.current.getAudioTracks();
      tracks.forEach((track) => {
        track.stop();
      });
    } else {
      console.log('No Tracks Found');
    }

    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setRecording(false);
      saveAudio();
    }
  };

  /*const handleReset = (e) => {
    if (recording) {
      stopRecording(e);
    }
    setTime({});
    setMiliseconds(0);
    setRecording(false);
    setMediaNotFound(false);
    setAudios([]);
    setAudioBlob(null);

    handleReset({
      time: {},
      miliseconds: 0,
      recording: false,
      mediaNotFound: false,
      audios: [],
      audioBlob: null
    });
  };*/

  const saveAudio = () => {
    const blob = new Blob(chunks.current, { type: mimeTypeToUseWhenRecording });
    const audioURL = window.URL.createObjectURL(blob);
    const audios = [audioURL];
    setAudios([audios]);
    setAudioBlob(blob);

    handleAudioStop({
      url: audioURL,
      blob: blob,
      chunks: chunks.current,
      duration: time
    });
  };

  useEffect(() => {
    if (audioBlob) {
      handleAudioUpload(audioBlob);
    }
  }, [audioBlob, handleAudioUpload]);

  return (
    <div className={`${styles.recorder_library_box}`}>
      <div className={styles.recorder_box}>
        <div className={`${styles.recorder_box_inner}`} >
        {!audioFile ? (
            <div className={`${styles.record_section} dropzone drag-zone`}>
              {!mediaNotFound ? (
                <div >
                  <div className={styles.duration_section}>
                    {!audioFile && (
                      <div className={styles.duration}>
                        <span className={styles.mins}>
                          {time.m !== undefined ? `${time.m <= 9 ? '0' + time.m : time.m}` : '00'}
                        </span>
                        <span className={styles.divider}>:</span>
                        <span className={styles.secs}>
                          {time.s !== undefined ? `${time.s <= 9 ? '0' + time.s : time.s}` : '00'}
                        </span>
                      </div>
                    )}

                  </div>
                  {!recording ? (
                  <button
                    onClick={startRecording}
                    className={styles.mic_icon}>
                    <span className={styles.microphone_icon_sec}>
                      <svg
                        className={styles.mic_icon_svg}
                        version='1.1'
                        xmlns='http://www.w3.org/2000/svg'
                        x='0px'
                        y='0px'
                        viewBox='0 0 1000 1000'
                        enableBackground='new 0 0 1000 1000'
                      >
                        <g>
                          <path d='M500,683.8c84.6,0,153.1-68.6,153.1-153.1V163.1C653.1,78.6,584.6,10,500,10c-84.6,0-153.1,68.6-153.1,153.1v367.5C346.9,615.2,415.4,683.8,500,683.8z M714.4,438.8v91.9C714.4,649,618.4,745,500,745c-118.4,0-214.4-96-214.4-214.4v-91.9h-61.3v91.9c0,141.9,107.2,258.7,245,273.9v124.2H346.9V990h306.3v-61.3H530.6V804.5c137.8-15.2,245-132.1,245-273.9v-91.9H714.4z' />
                        </g>
                      </svg>
                    </span>
                  </button>
                  ) : (
                    <div className={styles.record_controller}>
                      <a
                        onClick={stopRecording}
                        href=" #"
                        className={`${styles.icons} ${styles.stop}`}
                      >
                        <span className={styles.stop_icon}></span>
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <p style={{ color: '#fff', marginTop: 30, fontSize: 25 }}>Seems the site is Non-SSL</p>
              )}
            </div>) : ( 
              <div className='vertical-centering' style={{ height: "100px", position: "relative", margin: "auto"}}>
              <div className="spinner-grow text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-secondary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-success" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-danger" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-warning" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-info" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-light" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            <div className="spinner-grow text-dark" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
            </div>
            )}
        </div>
      </div>
    </div>
  );
};

export default Recorder;
