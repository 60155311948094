import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { React, useState, useEffect, useContext, useRef, useCallback, useMemo } from 'react';
import UserContext from '../../components/User_Context';
import * as labels from "../../components/localized/labels";

function AgGrid(props) {
    const { books, checkBox, onSelectionChange, relations } = props;
    const { user } = useContext(UserContext);
    const [rowData, setRowData] = useState([]);
    const gridRef = useRef();
    const [isLoaded, setIsLoaded] = useState(false);
    const isLoadedRef = useRef(isLoaded);

    const containerStyle = useMemo(() => ({ width: "100%", height: "100%" }), []);
    const gridStyle = useMemo(() => ({ height: 400, width: "100%" }), []);
    //rows
    useEffect(() => {
        if (books?.length > 0) {
            const transformedData = books.map(book => ({
                id: book._id,
                fileName: book.fileName,
                size: `${(book.size / 1024 / 1024).toFixed(2)}MB`,
                pages: book.pagesCount,
                isChecked: checkBox ? relations.find(r => r.documentId === book._id) ? true : false : false
            }));

            const orderedData = transformedData.sort((a, b) => {
                if (a.isChecked && !b.isChecked) return -1; // if a is checked and b is not checked 
                if (!a.isChecked && b.isChecked) return 1;  // if b is checked and b is not checked 
                return 0;                                 // if both are checked or unchecked
            });

            setRowData(orderedData);
        }
    }, [books]);
    //columns
    const [colDefs, setColDefs] = useState([
        checkBox ? {
            field: 'checkboxSelected',
            headerName: '',
            checkboxSelection: true,
            filter: false,
            minWidth: 50,
            maxWidth: 55,
            sortable: true,
            enableCellChangeFlash: true,
            sort: 'desc'
        } : null,
        checkBox ? {
            field: "fileName",
            headerName: labels.getText()["DOCUMENT_NAME"](),
        } : {
            field: "fileName",
            headerName: labels.getText()["DOCUMENT_NAME"](),

        },
    ].filter(Boolean));

    const defaultColDef = {
        flex: 1,
        resizable: false,
        filter: true,
        floatingFilter: true,
    };

    const onRowSelected = useCallback((event) => {

        onSelectionChange(event.node.data, event.node.selected);
    }, [onSelectionChange]);

    const onGridReady = useCallback((params) => {

        const api = params.api;
        if (api) {
            api.forEachNode((node) => {
                if (node.data) {
                    relations.forEach((relation) => {
                        if (relation.documentId === node.data.id && checkBox) {
                            node.setSelected(true);
                            node.setDataValue('checkboxSelected', true);
                        }
                    });
                }
            });
            setTimeout(() => {
                setIsLoaded(true);
            }, 1000);
        }
    }, [relations]);

    useEffect(() => {
        isLoadedRef.current = isLoaded;
    }, [isLoaded]);

    useEffect(() => {
        if (gridRef.current && isLoaded) {
            setIsLoaded(false);

            //console.log(gridRef.current.api.getRowNode(2));
            const api = gridRef.current.api;
            if (api) {
                api.forEachNode((node) => {
                    if (node.data) {
                        relations.forEach((relation) => {
                            if (relation.documentId === node.data.id && checkBox) {
                                node.setSelected(true);
                                node.setDataValue('checkboxSelected', true);
                            }
                        });
                    }
                });

                setIsLoaded(true);
            }
        }
    }, [relations, isLoaded]);

    return (
        <div style={containerStyle}>
            <div className="ag-theme-quartz" style={gridStyle}>
                <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={colDefs}
                    rowSelection="multiple"
                    defaultColDef={defaultColDef}
                    onSelectionChanged={(event) => console.log(event)}
                    onRowSelected={onRowSelected}
                    onGridReady={onGridReady}
                    rowMultiSelectWithClick={true}
                />
            </div>
        </div>
    );
}

export default AgGrid;
