import { useState, useEffect, useContext, useRef } from 'react';
import { Offcanvas, Modal } from 'react-bootstrap';
import UserContext from '../components/User_Context';
import { useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';
import * as labels from "../components/localized/labels";
import { GiConfirmed } from "react-icons/gi";
import { VscError } from "react-icons/vsc";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PagePreview from "../components/PagePreview.js";
import { trim } from 'jquery';
function ThenQPackageQuestions(props) {
    var { mobilePage = null, selectedCard, onButtonClick, toggleDivVisibility, onPackageCreated, onSubmitted = null, onDownload = null, onDownloadOpen = null, onSubmitted = null } = props;
    const [show, setShow] = useState(false);
    const [showTest, setShowTest] = useState(false);
    const standardProgress = useRef();
    const [newPackageId, setNewPackageId] = useState(null);
    const [openQuestions, setopenQuestions] = useState(selectedCard);
    const [showResult, setShowResult] = useState(false);
    const [showProgressModal, setShowProgressModal] = useState(false);
    const [questionPackages, setQuestionPackages] = useState(null);
    const [document, setDocument] = useState(null);
    const [_document, set_Document] = useState(null);
    const [folder, setFolder] = useState(null);
    const [showSelectedQuestionsCount, setSelectedQuestionsCount] = useState(null);
    const [showSelectedAnswersType, setSelectedAnswersType] = useState(null);
    const [CorrectAnswersQuantity, setCorrectAnswersQuantity] = useState(null);
    const [called, setCalled] = useState(false);
    const [pageDocument, setPageDocument] = useState(null);
    const [pageNumber, setPageNumber] = useState(null);
    const [showSelectedQuestionsDifficulty, setSelectedQuestionsDifficulty] = useState("medium");
    let { user } = useContext(UserContext);
    const { id, packageId = null, folderId, professorclassroomId, classroomId } = useParams();
    const [classroom, setClassroom] = useState(null);
    user = user ? JSON.parse(user) : null;
    var tempCount = 0;
    const handleNumberClick = (number) => {
        setSelectedQuestionsCount(prevNumber => (prevNumber === number ? null : number));
    };

    const managePageLinks = (documentID, pageNum) => {
        pageNum = pageNum.trim();
        documentID = documentID.trim();
        if (!folderId) {
            setPageDocument(id);
        }
        else if (folderId) {
            setPageDocument(documentID);
        }
        setPageNumber(parseInt(pageNum));
        console.log(parseInt(pageNum));
    }
    const handleTypeClick = (type) => {
        setSelectedAnswersType(prevType => (prevType === type ? null : type));
    };

    const handleChoiceClick = (choice) => {
        setCorrectAnswersQuantity(prevChoice => (prevChoice === choice ? null : choice));
    };
    const resetChoices = () => {
        setSelectedQuestionsCount(null);
        setSelectedAnswersType(null);
        setCorrectAnswersQuantity(null);
        setopenQuestions(null);
    }
    const updateProgressBar = () => {
        if (standardProgress.current) {
            standardProgress.current.style.width = `${tempCount}%`;
        }
    };
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const resetTest = () => {
        if (selectedCard.answersType == 'close_answers') {
            selectedCard.questions.forEach((q) => {
                q.Question_Answers.forEach((a) => {
                    if (a.selected) {
                        a.selected = null;
                    }
                }
                )
            });
        }
        else if (selectedCard.answersType == 'open') {
            selectedCard.questions.forEach((q) => {
                q.User_Answer = null;
            });
        }
    }
    const addUserAnswer = (index, text) => {
        selectedCard.questions[index].User_Answer = text;
        calculateScore();
    }
    const handleCloseTest = () => {
        if (selectedCard.answersType == 'close_answers') {
            selectedCard.questions.forEach((q) => {
                q.Question_Answers.forEach((a) => {
                    if (a.selected) {
                        a.selected = null;
                    }
                }
                )
            });
        }
        else if (selectedCard.answersType == 'open') {
            selectedCard.questions.forEach((q) => {
                q.User_Answer = null;
            });
        }
        selectedCard = null;
        setShowTest(false);
        setShowResult(false);
        toggleDivVisibility(true);
    }
    const calculateScore = async () => {
        var count = 0;
        if (selectedCard.answersType === 'close_answers') {
            selectedCard.questions.forEach(question => {
                if (question.Question_Answers.some(answer => answer.selected === true)) {
                    count++;
                }
            });
        }
        else if (selectedCard.answersType === 'open') {
            selectedCard.questions.forEach(question => {
                if (question.User_Answer && question.User_Answer != '') {
                    count++;
                }
            });
        }
        tempCount = selectedCard.questions?.length > 0
            ? Math.round((count * 100) / selectedCard.questions.length) : 0;
        updateProgressBar();
        //console.log(tempCount);
    }
    const setChoice = (index, choiceIndex) => {
        if (selectedCard.correctAnswers === 'single_choice') {
            selectedCard.questions[index].Question_Answers.forEach((a) => {
                if (a.selected) { a.selected = null; }
            });
            selectedCard.questions[index].Question_Answers[choiceIndex].selected = true;

        }
        else if (selectedCard.correctAnswers === 'multiple_choices') {
            if (selectedCard.questions[index].Question_Answers[choiceIndex].selected) {
                selectedCard.questions[index].Question_Answers[choiceIndex].selected = null;
            }
            else {
                selectedCard.questions[index].Question_Answers[choiceIndex].selected = true;

            }
        }
        calculateScore();
        // console.log(selectedCard.questions[index])
    };

    function createOffCanvas() {
        return (
            <>
                <Offcanvas show={show} onHide={handleClose} placement="end" className="full-screen-offcanvas">
                    <Offcanvas.Header style={{
                        position: 'sticky',
                        width: '100%',
                        top: '0px',
                        backgroundColor: 'white'
                    }}>
                        <Offcanvas.Title>{!mobilePage && (<h3 className="create-test-document ms-auto Text500__5" >{labels.getText()["CREATE_TEST"]()}</h3>)} {mobilePage && (<h4 className="create-test-document ms-auto Text500__5">{labels.getText()["CREATE_TEST"]()}</h4>)}</Offcanvas.Title>
                        <button className="custom-close-button Text500__5" onClick={handleClose}>
                            {labels.getText()["CLOSE"]()} <span className="close-icon">×</span>
                        </button>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="question-selection-container">
                            <div className="question-selection-numbers">
                                <div className="row">
                                    <p className="question-selection-title">{labels.getText()["QUESTION_NUMBER"]()}</p>
                                </div>
                                <div className="row">
                                    <button
                                        key={"5"}
                                        className={`custom-button ${showSelectedQuestionsCount === "5" ? 'selected' : ''} Text400__6`}
                                        onClick={() => handleNumberClick("5")}
                                    >
                                        5
                                    </button>
                                    <button
                                        key={"10"}
                                        className={`custom-button ${showSelectedQuestionsCount === "10" ? 'selected' : ''} Text400__6`}
                                        onClick={() => handleNumberClick("10")}
                                    >
                                        10
                                    </button>
                                    <button
                                        key={"15"}
                                        className={`custom-button ${showSelectedQuestionsCount === "15" ? 'selected' : ''} Text400__6`}
                                        onClick={() => handleNumberClick("15")}
                                    >
                                        15
                                    </button>
                                    <button
                                        key={"20"}
                                        className={`custom-button ${showSelectedQuestionsCount === "20" ? 'selected' : ''} Text400__6`}
                                        onClick={() => handleNumberClick("20")}
                                    >
                                        20
                                    </button>
                                </div>
                            </div>

                            <div className="question-selection-numbers">
                                <div className="row">
                                    <p className="question-selection-title">{labels.getText()["QUESTION_TYPES"]()}</p>
                                </div>
                                <div className="row">
                                    <button
                                        key="open"
                                        className={`custom-button ${showSelectedAnswersType === 'open' ? 'selected' : ''} Text400__6`}
                                        onClick={() => handleTypeClick('open')}
                                        disabled={CorrectAnswersQuantity}
                                    >
                                        {labels.getText()["OPEN_ANSWERS"]()}
                                    </button>
                                    <button
                                        key='close_answers'
                                        className={`custom-button ${showSelectedAnswersType === 'close_answers' ? 'selected' : ''} Text400__6`}
                                        onClick={() => handleTypeClick('close_answers')}
                                    >
                                        {labels.getText()["CLOSE_ANSWERS"]()}
                                    </button>
                                </div>
                            </div>

                            <div className="question-selection-numbers">
                                <div className="row">
                                    <p className="question-selection-title Text500__6">{labels.getText()["QUESTION_CHOICE"]()}</p>
                                </div>
                                <div className="row">
                                    <button
                                        key='single_choice'
                                        className={`custom-button ${CorrectAnswersQuantity === 'single_choice' ? 'selected' : ''} Text400__6`}
                                        onClick={() => handleChoiceClick('single_choice')}
                                        disabled={showSelectedAnswersType == 'open'}
                                    >
                                        {labels.getText()["SINGLE_CHOICES"]()}
                                    </button>
                                    <button
                                        key='multiple_choice'
                                        className={`custom-button ${CorrectAnswersQuantity === 'multiple_choices' ? 'selected' : ''} Text400__6`}
                                        onClick={() => handleChoiceClick('multiple_choices')}
                                        disabled={showSelectedAnswersType == 'open'}
                                    >
                                        {labels.getText()["MULTIPLE_CHOICES"]()}
                                    </button>
                                </div>
                            </div>
                        </div>

                    </Offcanvas.Body>
                    {!mobilePage && (<button className="then-big-button Text500__5" onClick={async () => {
                        let _newPackage = await generatePackage(showSelectedQuestionsCount, showSelectedQuestionsDifficulty, showSelectedAnswersType, CorrectAnswersQuantity);
                        if (_newPackage) {

                            setNewPackageId(_newPackage._id);

                        }
                        else {
                            setShowProgressModal(false);
                        }
                        refreshPackages();
                        resetChoices();
                    }}>{labels.getText()["CREATE_TEST"]()}</button>)}
                    {mobilePage && (<div className='off-canvas-bottom' style={
                        {
                            position: 'sticky',
                            width: '100%',
                        }}
                    >
                        <button className="then-big-button Text500__5" onClick={async () => {
                            let _newPackage = await generatePackage(showSelectedQuestionsCount, showSelectedQuestionsDifficulty, showSelectedAnswersType, CorrectAnswersQuantity);
                            if (_newPackage) {

                                setNewPackageId(_newPackage._id);

                            }
                            else {
                                setShowProgressModal(false);
                            }
                            refreshPackages();
                            resetChoices();
                        }}>{labels.getText()["CREATE_TEST"]()}</button>
                    </div>)}
                </Offcanvas>
            </>
        );
    }
    const _checkOpenAnswers = async () => {

        withReactContent(Swal).fire({
            title: <i>{labels.getText()["WAIT"]()}</i>,
            icon: "info",
            text: labels.getText()["CHECKING_ANSWERS"](),
            showConfirmButton: false,
            allowOutsideClick: false,
            showCloseButton: false
        });

        var correctAnswers = await api.sendMessage("checkOpenAnswers", {
            body: {
                // this is questions package ID, not doc id
                packageId: selectedCard._id,
                answersArray: selectedCard.questions,
            },
            user: user,
        });

        return new Promise((resolve, reject) => {
            for (var i = 0; i < selectedCard.questions.length; i++) {
                if (correctAnswers.aiAnswers[i].Correct != undefined && correctAnswers.aiAnswers[i].Correct != null) {
                    selectedCard.questions[i].Correct = correctAnswers.aiAnswers[i].Correct;
                    selectedCard.questions[i].__checked = correctAnswers.aiAnswers[i].__checked;
                }

            }

            resolve(selectedCard);
            console.log(selectedCard.questions);

            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: labels.getText()["ANSWERES_CHECKED"]()
            }).then((result) => {
                if (result.isConfirmed || result.isDismissed) {
                    setShowResult(true);
                }
            });
        });
    }
    const getDoc = async () => {
        return (await api.sendMessage("getDoc", {
            body: {
                id: id,
                // id: "66c72d1ce39527cbe116c134",
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).document;
    }

    const getClassroom = async () => {
        return (await api.sendMessage("getClassroomFromId", {
            body: {
                id: professorclassroomId || classroomId,
            },
            user: user,
        })).classroom;
    }

    function createProgressModal() {

        let _newPackage = questionPackages.find(x => x._id == newPackageId);

        if (_newPackage) {
            if (!called) { onPackageCreated(); setCalled(true); }
            let packageIndex = questionPackages.findIndex(x => x._id == newPackageId) + 1;
            if (_newPackage.status == "done") {
                if (_newPackage.questions.length < showSelectedQuestionsCount) {
                    return (
                        <Modal show={showProgressModal} dialogClassName="modal-dialog-centered">
                            <Modal.Header closeButton onClick={(e) => { setNewPackageId(null); setShowProgressModal(false); setCalled(false); }}>
                                <Modal.Title className='Text700__5'>{labels.getText()["PACKAGE_NUMBER"]({ number: packageIndex })}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='Text500__5'>
                                <div className="modal-body text-center">
                                    <p>{labels.getText()["PACKAGE_PROCCESS_FINISH"]()}</p>
                                </div>
                                <div className="modal-body text-center">
                                    <p>{labels.getText()["PACKAGE_PROCCESS_FINISH_LESS_QUESTIONS"]()}</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="then-small-button Text500__5" type="button" onClick={(e) => { setNewPackageId(null); setShowProgressModal(false); setCalled(false); }}>
                                    {labels.getText()["CLOSE"]()}
                                </button>
                            </Modal.Footer>
                        </Modal>
                    );

                }
                else {
                    return (
                        <Modal show={showProgressModal} dialogClassName="modal-dialog-centered">
                            <Modal.Header closeButton onClick={(e) => { setNewPackageId(null); setShowProgressModal(false); setCalled(false); }}>
                                <Modal.Title className='Text700__5'>{labels.getText()["PACKAGE_NUMBER"]({ number: packageIndex })}</Modal.Title>
                            </Modal.Header>
                            <Modal.Body className='Text500__5'>
                                <div className="modal-body text-center">
                                    <p>{labels.getText()["PACKAGE_PROCCESS_FINISH"]()}</p>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <button className="then-small-button" type="button" onClick={(e) => { setNewPackageId(null); setShowProgressModal(false); setCalled(false); }}>
                                    {labels.getText()["CLOSE"]()}
                                </button>
                            </Modal.Footer>
                        </Modal>
                    );
                }
            }
        }
        else {
            return (
                <Modal show={showProgressModal} dialogClassName="modal-dialog-centered">
                    <Modal.Header closeButton onClick={(e) => { setNewPackageId(null) }}>
                        <Modal.Title className='Text700__5'>{labels.getText()["PACKAGE"]()}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='Text500__5'>
                        <div>
                            <label>{labels.getText()["PREPARING_PACKAGE"]()}</label>
                            <div className="progress" style={{ marginBottom: "2em", height: "3em", width: "100%", marginLeft: "auto", marginRight: "auto", marginTop: "1em" }}>
                                <div className="progress-bar" role="progressbar" style={{ width: `45%`, backgroundColor: 'var(--Violet__500, #662FE7)' }}> {'50%'}</div>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="then-small-button" type="button" onClick={(e) => { setNewPackageId(null); setShowProgressModal(false) }}>
                            {labels.getText()["CLOSE"]()}
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }
    }
    const _drawProgressBar = () => {
        return (
            <div
                style={
                    {

                        visibility: tempCount >= 0 ? "visible" : "hidden",
                        display: tempCount >= 0 ? "block" : "none",
                        position: 'sticky',
                        top: '2em',
                        width: '100%',
                    }
                }>
                <div className="progress mx-auto w-100" style={{ marginBottom: "2em", height: "2em" }}>
                    <div ref={standardProgress} className="progress-bar" role="progressbar" style={{ backgroundColor: '#662FE7', width: `${tempCount}%`, borderRadius: '30px' }}></div>
                </div>
            </div>
        );
    }
    const _drawResultBar = () => {
        var _maxScore = selectedCard
            .questions.length;

        if (selectedCard.answersType == "open") {
            var _actualScore = (openQuestions
                .questions.filter(x => x.Correct)
                || []).length;
        }
        else {
            if (selectedCard.correctAnswers === 'single_choice') {
                var _actualScore = (selectedCard
                    .questions.filter(x => x.Question_Answers.find(y => y.Correct && y.selected))
                    || []).length;
            } else {
                var _actualScore = 0;
                selectedCard.questions.forEach((question, index) => {
                    if ((question.Question_Answers.filter(y => y.Correct && y.selected) || []).length == (question.Question_Answers.filter(y => y.Correct) || []).length && (question.Question_Answers.filter(y => y.Correct && y.selected) || []).length == (question.Question_Answers.filter(y => y.selected) || []).length) { _actualScore++; }
                })
            }

        }


        if (_maxScore == _actualScore) {
            return (<div className="alert alert-success" role="alert" style={{ width: '100%', textAlign: 'center' }}>
                <h4 className="alert-heading"> {labels.getText()["WELL_DONE"]()}
                </h4>
                <p className="Text400__6" >
                    {labels.getText()["ALL_CORRECT"]({ maxScore: _maxScore })}
                </p>
                <hr />
                <p className="mb-0 Text400__6">{labels.getText()["TOPIC_EXPERT"]()}
                </p>
            </div>)
        }
        else {
            if (_maxScore > 0 && _actualScore / _maxScore > 0.6) {
                return (<div className="alert alert-success" role="alert" style={{ width: '100%', textAlign: 'center' }}>
                    <h4 className="alert-heading"> {labels.getText()["GOOD_JOB"]()}</h4>
                    <p className="Text400__6">{labels.getText()["SOMEONE_CORRECT"]({ actualScore: _actualScore, maxScore: _maxScore })}</p>
                    <hr />
                    <p className="mb-0 Text400__6">{labels.getText()["TEST_TRY_AGAIN"]()}
                        !</p>
                </div>)
            }
            else {
                return (<div className="alert alert-warning" role="alert" style={{ width: '100%', textAlign: 'center' }}>
                    <h4 className="alert-heading">{labels.getText()["STUDY_MORE"]()}</h4>
                    <p className="Text400__6">{labels.getText()["SOMEONE_CORRECT_SAD"]({ actualScore: _actualScore, maxScore: _maxScore })}</p>
                    <hr />
                    <p className="mb-0 Text400__6">{labels.getText()["TEST_TRY_AGAIN"]()}</p>
                </div>)
            }
        }


    }
    const getFolder = async () => {
        return (await api.sendMessage("getFolder", {
            body: {
                id: folderId,
                classroom: professorclassroomId || classroomId
            },
            user: user,
        })).folder;
    }
    const CheckFlag = (questionsPackage) => {
        var count = 0;
        questionsPackage.questions.forEach((question) => {
            if (question.__checked == true) count++;
        });
        return count;
    }
    const generatePackage = async (questionsCount, testDifficulty, answersType, correctAnswers) => {

        setShowProgressModal(true);
        if ((questionsCount && testDifficulty && answersType && correctAnswers) || (questionsCount && testDifficulty && answersType == 'open')) {
            setShow(false);

            let gqp = await api.sendMessage("generateQuestionsPackage", {
                body: {
                    folderId: folderId,
                    id: id,
                    questionsCount: questionsCount,
                    difficulty: testDifficulty,
                    answersType: answersType,
                    correctAnswers: correctAnswers,
                    classroom: professorclassroomId || classroomId
                },
                user: user,
            })

            if(gqp.success == false){
                withReactContent(Swal).fire({
                    title: <i>{labels.getText()["OPS"]()}</i>,
                    icon: "errorr",
                    text:gqp.message,
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    showCloseButton: false
                });
            }
            else
            {
                return gqp.questionsPackage;
            }

         

        }
        else {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["CANCELLED"]()}</i>,
                icon: "error",
                text: labels.getText()["ERROR_CREATE_TEST"](),
                showConfirmButton: false,
                allowOutsideClick: false,
                showCloseButton: true,
            });
        }
    }

    const getPackages = async () => {
        return (await api.sendMessage("getQuestionsPackages", {
            body: {
                folderId: folderId,
                docId: id,
                // docId: "66c72d1ce39527cbe116c134",
                classroomId: professorclassroomId || classroomId
            },
            user: user,
        })).questionsPackages;
    }

    const refreshPackages = async () => {
        let _allDone = false;

        while (!_allDone) {
            try {
                // Get the packages from the API

                var _packages = null;

                //TODO CLEANING
                if (id) {
                    _packages = await getPackages();
                }
                else {
                    _packages = await getPackages();
                }

                // Refresh the packages state variable, bounded by the list component
                setQuestionPackages(_packages);

                // Check if any document is still indexing
                if (!_packages.find(x => x.status === "processing")) {
                    _allDone = true;
                    console.log("All documents are OK");
                } else {
                    console.log("Waiting for indexing in progress");
                    await new Promise(resolve => setTimeout(resolve, 2000));
                }
            } catch (error) {
                console.error("Error while refreshing packages:", error);
                _allDone = true;  // To break out of the loop in case of error
            }
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const _book = await getDoc();
                const _folder = await getFolder();
                const _classroom = await getClassroom();


                // We call setDocument and setFolder outside of `getPackages`
                setDocument(_book);
                setFolder(_folder);
                setClassroom(_classroom);

                await refreshPackages();
            } catch (error) {
                console.error("Error in useEffect:", error);
            }
        })();
    }, []);
    useEffect(() => {
        (async () => {
            if (selectedCard) {
                setShowTest(true);
            }
        })();
    }, [selectedCard]);
    if ((!questionPackages && !document) || (!questionPackages && !folder)) {
        return (
            <div className="App App-body">
                {/* {SetMenu({ user: user, professorclassroomId, classroomId })} */}
                <h1>{labels.getText()["WAITING_FOR_DATA"]()}</h1>
            </div>
        );
    }
    else {
        return (
            <div className="col-lg-7 wow fadeIn column-dx-bg d-flex justify-content-center align-items-center" id='secondColumn' style={{ flexDirection: 'column', background: !selectedCard && mobilePage ? "transparent" : " ", }}>
                {createOffCanvas()}
                {createProgressModal()}
                {showTest && !showResult && (_drawProgressBar())}
                {showResult && (_drawResultBar())}
                {showTest ? (<div className="question-block-wrapper">
                    <div className='question-block-package'>{labels.getText()["PACKAGE"]()} #{selectedCard.index} </div>
                    <div className='question-block-quit'><button className="custom-close-button-test" onClick={e => {
                        onButtonClick();
                        handleCloseTest();
                    }}>
                        {labels.getText()["CLOSE"]()} <span className="close-icon">×</span>
                    </button> </div> </div>) : <div></div>}
                {!showResult && showTest && selectedCard && Array.isArray(selectedCard.questions) ? (
                    selectedCard.questions.map((question, qIndex) => (
                        <div key={qIndex} className="question-block">
                            <p className="questionTitle">{qIndex + 1} - {question.Question_Text}</p>

                            {Array.isArray(question.Question_Answers) ? (
                                selectedCard.correctAnswers === 'multiple_choices' ? (
                                    question.Question_Answers.map((option, oIndex) => (
                                        <div key={oIndex} className="option">
                                            <input
                                                type="checkbox"
                                                id={`q${qIndex}o${oIndex}`}
                                                name={`question${qIndex}`}
                                                value={option.Answer_Text}
                                                checked={option.selected}
                                                onClick={e => {
                                                    setChoice(qIndex, oIndex);
                                                }}
                                            />
                                            <label className='Text400__6' htmlFor={`q${qIndex}o${oIndex}`}>
                                                <span className='Text400__6'>{String.fromCharCode(65 + oIndex)}</span> {option.Answer_Text}
                                            </label>
                                        </div>
                                    ))
                                ) : (
                                    question.Question_Answers.map((option, oIndex) => (
                                        <div key={oIndex} className="option">
                                            <input
                                                type="radio"
                                                id={`q${qIndex}o${oIndex}`}
                                                name={`question${qIndex}`}
                                                value={option.Answer_Text}
                                                checked={option.selected}
                                                onClick={e => {
                                                    setChoice(qIndex, oIndex);
                                                }}
                                            />
                                            <label className='Text400__6' htmlFor={`q${qIndex}o${oIndex}`}>
                                                <span className='Text400__6' >{String.fromCharCode(65 + oIndex)}</span> {option.Answer_Text}
                                            </label>
                                        </div>
                                    ))
                                )
                            ) : (
                                question.Question_Answer && (
                                    <div className="text-answer">
                                        <textarea
                                            className='Text400__6'
                                            id={`q${qIndex}textAnswer`}
                                            name={`question${qIndex}textAnswer`}
                                            rows="5"
                                            cols="50"
                                            onChange={(e) => { addUserAnswer(qIndex, e.target.value) }}
                                        ></textarea>
                                    </div>
                                )
                            )}
                        </div>
                    ))
                ) : !selectedCard && !mobilePage && (
                    <div className="top-card">
                        <div className="div-top-card-test">  <img className="add-test-icon" src="/huge-icon/QA/test.svg"></img></div>
                        <button className="then-big-button mt-3 Text500__5" onClick={e => {
                            if (!show) {
                                handleShow();
                            }
                            else { handleClose(); }
                        }}>{labels.getText()["CREATE_TEST"]()}  </button>
                    </div>
                )}

                {!showResult && showTest && selectedCard ? (<div className='question-bottom'>
                    <button className="then-small-button-test mt-3 Text700__5" onClick={async () => {
                        if (selectedCard.answersType == 'close_answers') {
                            setShowResult(true);
                            onSubmitted(selectedCard);
                        }
                        else if (selectedCard.answersType == 'open') {
                            var checkedAnswers = await _checkOpenAnswers();
                            setopenQuestions(checkedAnswers);
                            onSubmitted(checkedAnswers);

                        }
                        setShowTest(true);
                        window.scrollTo({ top: 0, behavior: 'smooth' });

                    }}>{labels.getText()["SUBMIT_BUTTON"]()}</button>
                    <button className="then-small-button-PDF-test mt-3 Text500__5" onClick={async () => {
                        if (selectedCard.answersType == "open") {
                            if (CheckFlag(selectedCard) != selectedCard.questions.length) {
                                var checkedAnswers = await _checkOpenAnswers();
                                setopenQuestions(checkedAnswers);

                            }
                            onDownloadOpen(selectedCard);
                        }
                        if (onSubmitted && selectedCard.answersType != "open") {
                            onDownload(selectedCard, 0);
                        }
                    }}>{labels.getText()["DOWNLOAD_PDF"]()}<img src="/huge-icon/filesandfolder/bulk/pdficon.svg" alt="Icon"></img></button>
                </div>) : null}

                {showResult && selectedCard && Array.isArray(selectedCard.questions) &&
                    selectedCard.questions.map((question, qIndex) => {
                        var hasSelectedOption = null;
                        if (selectedCard.answersType === 'close_answers') {
                            hasSelectedOption = question.Question_Answers.some(option => option.selected);
                        }
                        return (
                            <div key={qIndex} className="card card-result mb-2">
                                <div className="card-header">
                                    <h5 className="card-title">{labels.getText()["QUESTION"]()} {qIndex + 1}: {question.Question_Text}</h5>
                                </div>
                                <div className="card-body">
                                    <h6 className="card-subtitle mb-2 text-muted Text400__6">{labels.getText()["YOUR_ANSWER"]()}</h6>
                                    {selectedCard.answersType === 'close_answers' && Array.isArray(question.Question_Answers) && (
                                        question.Question_Answers.map((option, oIndex) => (
                                            option.selected && (

                                                <div className='wrapper' style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    {option.Correct ?
                                                        (
                                                            <div className="text-success" style={{ marginRight: '0.5em' }}><GiConfirmed style={{ fontSize: "2em" }}

                                                            /></div>
                                                        )
                                                        :
                                                        (
                                                            <div className="text-danger" style={{ marginRight: '0.5em' }}><VscError style={{ fontSize: "2em" }}

                                                            /></div>
                                                        )
                                                    }
                                                    <div key={oIndex} className={`result-input d-flex align-items-center mb-2 p-2`}>
                                                        <input
                                                            type={selectedCard.correctAnswers === 'multiple_choices' ? 'checkbox' : 'radio'}
                                                            id={`result-q${qIndex}o${oIndex}`}
                                                            name={`result-question${qIndex}`}
                                                            value={option.Answer_Text}
                                                            checked={option.selected}
                                                            disabled={true}
                                                            readOnly
                                                            className="me-2 Text400__6"
                                                        />
                                                        <label className='Text400__6 mb-0' htmlFor={`result-q${qIndex}o${oIndex}`}>
                                                            <span className='Text400__6'>{String.fromCharCode(65 + oIndex)}</span> {option.Answer_Text}
                                                        </label>

                                                    </div>
                                                </div>

                                            )
                                        ))
                                    )}
                                    {selectedCard.answersType === 'open' && (
                                        <div className='wrapper' style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            {question.User_Answer ? (
                                                <div className='wrapper' style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    {question.Correct ?
                                                        (
                                                            <div className="text-success" style={{ marginRight: '0.5em' }}><GiConfirmed style={{ fontSize: "2em" }}

                                                            /></div>
                                                        )
                                                        :
                                                        (
                                                            <div className="text-danger" style={{ marginRight: '0.5em' }}><VscError style={{ fontSize: "2em" }}

                                                            /></div>
                                                        )
                                                    }
                                                    <div
                                                        className={`d-flex align-items-center mb-2 p-2`}
                                                    >
                                                        <p className="mb-0 Text400__6">{question.User_Answer}</p>
                                                    </div>
                                                </div>
                                            ) : (
                                                <div className="alert alert-warning Text400__6" role="alert">
                                                    {labels.getText()["NOT_ANSWERED"]()}
                                                </div>
                                            )}
                                        </div>
                                    )}
                                    {selectedCard.answersType === 'close_answers' && !hasSelectedOption && (
                                        <div className="alert alert-warning Text400__6" role="alert">
                                            {labels.getText()["NOT_ANSWERED"]()}
                                        </div>
                                    )}
                                </div>
                                <div className="card-footer">
                                    <h6 className="card-subtitle mb-2 text-muted Text400__6"> {labels.getText()["CORRECT_ANSWER"]()}</h6>
                                    {selectedCard.answersType === 'close_answers' && Array.isArray(question.Question_Answers) && (
                                        question.Question_Answers.map((option, oIndex) => (
                                            option.Correct && !option.selected ? (
                                                <div className='wrapper' style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                                    <div className="result-wrapper">
                                                        <div key={oIndex} className="correct-result-input d-flex align-items-center mb-2 p-2">
                                                            <input
                                                                type={selectedCard.correctAnswers === 'multiple_choices' ? 'checkbox' : 'radio'}
                                                                id={`result-correct-q${qIndex}o${oIndex}`}
                                                                name={`result-correct-question${qIndex}`}
                                                                value={option.Answer_Text}
                                                                checked={option.selected}
                                                                disabled={true}
                                                                readOnly
                                                                className="me-2 Text400__6"
                                                            />
                                                            <label className='Text400__6' htmlFor={`result-correct-q${qIndex}o${oIndex}`}>
                                                                <span className='Text400__6'>{String.fromCharCode(65 + oIndex)}</span> <strong>{option.Answer_Text}</strong>
                                                            </label>

                                                        </div>
                                                        <div className="mt-3 Text400__6">
                                                            <strong> {option?.Question_Answer}</strong >
                                                            <small>  {option.PageNumber && (<p>{labels.getText()["PAGES_ANSWER"]()}
                                                                {Array.isArray(option.PageNumber) && (option.PageNumber.map((page, index) => (<span key={page}>
                                                                    <a data-page={page} href={`#Page/${page}`} onClick={e => { managePageLinks(question.BookID, page) }}>{page}</a>
                                                                    {index < option.PageNumber.length - 1 && ', '}
                                                                </span>)))} </p>)}
                                                                <p><i>... {option?.AnswerLocation}</i></p>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : (option.selected && option.Correct && (
                                                <div className='wrapper' style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>

                                                    <div className="mt-3 Text400__6">
                                                        {selectedCard.correctAnswers === 'single_choice' && (<small>  {option.PageNumber && (<p>{labels.getText()["PAGES_ANSWER"]()}
                                                            {Array.isArray(option.PageNumber) && (option.PageNumber.map((page, index) => (<span key={page}>
                                                                <a data-page={page} href={`#Page/${page}`} onClick={e => { managePageLinks(question.BookID, page) }}>{page}</a>
                                                                {index < option.PageNumber.length - 1 && ', '}
                                                            </span>)))} </p>)}
                                                            <p><i>... {option?.AnswerLocation}</i></p>
                                                        </small>)}
                                                        {selectedCard.correctAnswers === 'multiple_choices' && (
                                                            <small>
                                                                {
                                                                    option.Correct && (
                                                                        <div key={`result-correct-q${qIndex}o${oIndex}`}>
                                                                            <label className='Text400__6' htmlFor={`result-correct-q${qIndex}o${oIndex}`}>
                                                                                <span className='Text400__6'>{String.fromCharCode(65 + oIndex)}</span> {option.Answer_Text}
                                                                            </label>
                                                                            <small>  {option.PageNumber && (<p>{labels.getText()["PAGES_ANSWER"]()}
                                                                                {Array.isArray(option.PageNumber) && (option.PageNumber.map((page, index) => (<span key={page}>
                                                                                    <a data-page={page} href={`#Page/${page}`} onClick={e => { managePageLinks(question.BookID, page) }}>{page}</a>
                                                                                    {index < option.PageNumber.length - 1 && ', '}
                                                                                </span>)))} </p>)}
                                                                                <p><i>... {option?.AnswerLocation}</i></p>
                                                                            </small>
                                                                        </div>
                                                                    )
                                                                }
                                                            </small>
                                                        )}
                                                    </div>
                                                </div>
                                            ))
                                        )))}
                                    {selectedCard.answersType === 'open' && Array.isArray(question.Page_Number) && question.Page_Number.length > 0 ? (
                                        <div className='wrapper' style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <div className="mt-3 Text400__6">
                                                <p>{labels.getText()["CORRECT_ANSWER_SMALL"]()}: </p> <strong>{question.Question_Answer}</strong >
                                                <small>  <p>{labels.getText()["PAGES_ANSWER"]()}
                                                    {Array.isArray(question.Page_Number) && (question.Page_Number.map((page, index) => (<span key={page}>
                                                        <a data-page={page} href={`#Page/${page}`} onClick={e => { managePageLinks(question.BookID, page) }}>{page}</a>
                                                        {index < question.Page_Number.length - 1 && ', '}
                                                    </span>)))} </p>
                                                    <p><i>... {question?.Question_Context}</i></p>
                                                </small>
                                            </div>
                                        </div>
                                    ) : (selectedCard.answersType === 'open' && !question.Page_Number ? (
                                        <div className='wrapper' style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                        }}>
                                            <div className="mt-3 Text400__6">
                                                <p><strong>{question.Question_Answer}</strong > </p>
                                                <small>
                                                    <p><i>... {question?.Question_Context}</i></p>
                                                </small>
                                            </div>
                                        </div>) : (<div></div>))}

                                </div>
                            </div>
                        );
                    })
                }

                {showResult && showTest && selectedCard ? (<div className='question-bottom'>
                    <button className="then-small-button-test mt-3  Text700__5" onClick={e => {
                        resetTest();
                        setShowResult(false);
                        setShowTest(true);
                        window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}> {labels.getText()["RETURN_TO_TEST_NEW"]()}</button>
                    <button className="then-small-button-PDF-test mt-3  Text500__5" onClick={async () => {
                        if (selectedCard.answersType == "open") {
                            if (CheckFlag(selectedCard) != selectedCard.questions.length) {
                                var checkedAnswers = await _checkOpenAnswers(selectedCard);
                                setopenQuestions(checkedAnswers);
                            }
                            onDownloadOpen(selectedCard);
                        }
                        if (onSubmitted && selectedCard.answersType != "open") {
                            onDownload(selectedCard, 1);
                        }
                    }}
                    >
                        {labels.getText()["DOWNLOAD_PDF"]()}<img src="/huge-icon/filesandfolder/bulk/pdficon.svg" alt="Icon"></img></button>
                </div>) : null}
                {!selectedCard && mobilePage && (<div className="add-test-bottom"
                >
                    <button className="then-big-button mx-auto Text500__5" onClick={e => {
                        if (!show) {
                            handleShow();
                        }
                        else { handleClose(); }
                    }}>{labels.getText()["CREATE_TEST"]()}  </button>
                </div>)}
                {pageDocument && pageNumber && (
                    <PagePreview document={pageDocument} page={pageNumber} user={user} onClose={() => {
                        setPageNumber(null);
                    }}>

                    </PagePreview>

                )}
            </div>





        )

    }
}

export default ThenQPackageQuestions;