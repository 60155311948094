import '../App.css';
import { useContext } from "react";
import UserContext from '../components/User_Context';
import { Navigate } from 'react-router-dom';
import { Table } from 'react-bootstrap';
import * as api from '../api/apiConnector';
import React, { useState, useEffect } from 'react';
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu } from '../components/Navigation';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ProfileCards from '../components/ProfileCards';

function User_Payments_Screen() {
    let { user } = useContext(UserContext);
    const [payments, setPayments] = useState([]);
    user = user ? JSON.parse(user) : null;
    async function getPayments() {
        const result = await api.sendMessage('getPayments', { 
            user: user
         });
        if(result.success){
            //console.log(result);
            setPayments(result.payments);
        } else {
            //console.log(result);
            const MySwal = withReactContent(Swal);
            MySwal.fire({
                title: 'Error!',
                text: 'Failed to get payments',
                icon: 'error',
                confirmButtonText: 'OK'
            });
        }
    }
    useEffect(() => {
        (async () => {
            getPayments();
          })();
    }, []);


    if (user) {
        return (
            <div className='wrap'>
                {SetMenu({ user: user })}
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-5 wow fadeIn column-sx" data-wow-delay="0.1s">
                            <div className="stack-title">
                                {getBreadcrumb({
                                    page: "user_payment_profile",
                                })}
                                <h1 className="document-boost"> {labels.getText()["PAYMENT_DETAILS"]()}</h1>
                            </div>
                            <div className="row">
                                <p className="text-start Text500__5">{labels.getText()["PAYMENT_DETAILS_DESC"]()}</p>
                                <div className="container mt-5">
                                        {payments.length > 0 && (
                                    <Table responsive striped bordered hover size='sm'>
                                        <thead>
                                            <tr>
                                            <th>#</th>
                                            <th>{labels.getText()["STATUS"]()}</th>
                                            <th>{labels.getText()["SUBSCRIPTION"]()}</th>
                                            <th>{labels.getText()["AMOUNT"]()}</th>
                                            <th>{labels.getText()["DATE"]()}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {payments.map((payment, index) => (
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    {payment.status === "APPROVAL_PENDING" ? (
                                                        <td><a href={payment.link.href}>{payment.status}</a></td>
                                                    ) : (
                                                        <td>{payment.status}</td>
                                                    )}
                                                    <td>{payment.plan}</td>
                                                    <td>{payment.amount + payment.tax + ' ' + payment.currency_code}</td>
                                                    <td>{payment.time}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                        )}
                                        {payments.length === 0 && (
                                            <h5 className="text-center">{labels.getText()["NO_PAYMENTS"]()}</h5>
                                        )}
                                    </div>
                            </div>
                        </div>
                        <ProfileCards />
                    </div>
                </div>
            </div>
        );

    }
    else {
        SetMenu({ user: user });
        return (<Navigate to="/" />);
    }

}

export default User_Payments_Screen;