import '../App.css';
import { useContext, useEffect } from "react";
import UserContext from '../components/User_Context';
import { Navigate } from 'react-router-dom';
import * as api from '../api/apiConnector';
import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as labels from "../components/localized/labels";
import { getBreadcrumb, SetMenu, } from '../components/Navigation';
import { useNavigate } from 'react-router-dom';
import OtpInput from 'react-otp-input';
import ProfileCards from '../components/ProfileCards';
import Select from 'react-select';


function User_Profile_Screen() {
    const navigate = useNavigate();
    const [refreshKey, setRefreshKey] = useState(0);
    const [countries, setCountries] = useState([]);
    const [otp, setOtp] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showOTPModal, setShowOTPModal] = useState(false);
    let { user, login, logout } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [email, setEmail] = useState(user.userName);
    const [firstName, setFirstName] = useState(user.name);
    const [lastName, setLastName] = useState(user.lastName);
    const [isVisible, setIsVisible] = useState(false);
    const [country, setCountry] = useState(user.country);//useState(countries.find(c => c.value === user.country).label);
    const [dateOfBirth, setDateOfBirth] = useState(user.dateOfBirth != null ? new Date(user.dateOfBirth).toISOString().split('T')[0] : null);
    const [accountType, setAccountType] = useState(user.isProfessor ? "Professor" : "Student");
    const [isChanged, setIsChanged] = useState(false);
    const [account, setAccount] = useState({
        userName: user.userName,
        lastName: null,
        name: null,
        dateOfBirth: null,
        country: user.country,
        token: user.token,
        _language: localStorage.getItem("active_language"),
        isProfessor: user.isProfessor,
        isActive: user.isActive
    });
    const refreshSelect = () => {
        setRefreshKey(prevKey => prevKey + 1);
    };
    useEffect(() => {
        if (!user.country) {
            setIsVisible(true);
            //console.log("country not set")
        }
        const fetchCountries = async () => {
            if (countries.length === 0) {
                const response = await api.sendMessage("getCountries", {});
                setCountries(response.map(c => ({ label: c.country_name, value: c.country_code })));

                setCountry(response.find(c => c.country_code === user.country)?.country_name ?? "");
            }
        };
        fetchCountries();
    }, [countries, user.country]);
    function validatePassword(password) {
        const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-])[A-Za-z\d!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]{8,999}$/;

        return regex.test(password);
    }
    const handleInputChange = () => {
        setIsChanged(true);
    };

    /*const chooseCountry = (country) => {
        setCountry(country);
        setIsChanged(true);
    }*/

    const handleShow = () => {
        setShowModal(true);
    };
    const handleDeleteShow = () => {
        setShowDeleteModal(true);
    };
    async function checkOTP() {
        var result = (await api.sendMessage("checkUserDeleteOTP", {
            body: {
                otp: otp,
                userName: user.userName
            },
            user: user
        }));
        if (result.success) {
            setOtp(null);
            setShowOTPModal(false);

            deleteUser();
        }
        else if (result.error) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: result.error,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 2000
            });
            setOtp(null);

        }
    }
    async function resendDeleteEmail(email) {
        var result = (await api.sendMessage("resendDeleteEmail", {
            body: {
                email: email,
            },
            user: user
        }));
        if (result.success) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                timer: 2000
            });
        }
    }
    async function AddUserDeleteOTP() {
        var result = (await api.sendMessage("AddUserDeleteOTP", {
            body: {
                user: user
            },
            user: user
        }));
        if (result.success) {
            setShowDeleteModal(false);
            setShowOTPModal(true)
        }
    }
    async function doPasswordChange(oldPassword, newPassword) {
        var result = (await api.sendMessage("changePassword", {
            body: {
                oldPassword: oldPassword,
                newPassword: newPassword
            },
            user: user
        }));
        if (result.success) {
            setShowModal(false);
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true
            });

        }
        else if (result.error) {
            setShowModal(false);
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "error",
                text: result.error,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true
            });

        }
    }
    function createDeleteAccountModal() {
        return (
            <Modal show={showDeleteModal} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton onClick={(e) => { setShowDeleteModal(false) }}>
                    <Modal.Title>{labels.getText()["DELETE_ACCOUNT_TITLE"]()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {labels.getText()["DELETE_ACCOUNT_BODY"]()}
                </Modal.Body>
                <Modal.Footer>
                    <div className="container">
                        <div className="row">
                            <div className="col text-left Text500__5">
                                <button type="submit" className="then-small-button-danger Text500__5" onClick={(e) => { AddUserDeleteOTP(); }}>{labels.getText()["YES"]()}</button>
                            </div>
                            <div className="col d-flex justify-content-end">
                                <button type="submit" className="then-small-button" onClick={(e) => { setShowDeleteModal(false) }}>{labels.getText()["CANCEL"]()}</button>
                            </div>
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
    async function deleteUser() {
        var result = (await api.sendMessage("deleteUser", {
            body: {
                user: user
            },
            user: user
        }));
        if (result.success) {
            setShowModal(false);
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 5000
            }).then((result) => {
                if (result.isConfirmed || result.isDismissed) {
                    logout();
                    navigate("/");
                }
            });

        }
    }
    function createChangePasswordModal() {
        return (
            <Modal show={showModal} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton onClick={(e) => { setShowModal(false) }}>
                    <Modal.Title className='Text700__6'>{labels.getText()["CHANGE_PASSWORD"]()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="password-requirements">
                        <p className='Text700__6'>{labels.getText()["PASS_REQS"]()}</p>
                        <ul className='Text500__5'>
                            <li>{labels.getText()["PASS_REQS1"]()}</li>
                            <li>{labels.getText()["PASS_REQS2"]()}</li>
                            <li>{labels.getText()["PASS_REQS3"]()}</li>
                            <li>{labels.getText()["PASS_REQS4"]()}</li>
                            <li>{labels.getText()["PASS_REQS5"]()}</li>
                        </ul>
                    </div>
                    <div className="form-group mt-3 Text500__5">
                        <label for="oldPassword">{labels.getText()["OLD_PASSWORD"]()}</label>
                        <input type="password" className="form-control" aria-describedby="emailHelp" placeholder={labels.getText()["ENTER_OLD_PASSWORD"]()} onChange={(e) => {
                            setOldPassword(e.target.value);
                        }} />
                    </div>
                    <div className="form-group mt-3 Text500__5">
                        <label for="newPassword">{labels.getText()["NEW_PASSWORD"]()}</label>
                        <input type="password" className="form-control" placeholder={labels.getText()["ENTER_NEW_PASSWORD"]()} onChange={(e) => {
                            setNewPassword(e.target.value);
                        }} />
                    </div>
                    <button className="then-small-button mt-3" onClick={(e) => {
                        if (!validatePassword(newPassword)) {
                            alert(labels.getText()["PASSWORD_REQ"]());
                        }
                        else
                            doPasswordChange(oldPassword, newPassword);
                    }}>{labels.getText()["CHANGE_PASSWORD"]()}</button>
                </Modal.Body>
            </Modal>
        );
    }
    async function handleProfessor() {
        var result = (await api.sendMessage("askForProfessorRole", {
            body: {
                user: user
            },
            user: user
        }));
        if (result.success) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["DONE"]()}</i>,
                icon: "success",
                text: result.message,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 5000
            }).then((result) => {
                if (result.isConfirmed || result.isDismissed) {
                    var updatedAccount = user;
                    updatedAccount.isProfessor=true;
                    updatedAccount.isConfirmedProfessor=false;
                    login(JSON.stringify(updatedAccount));
                    window.location.reload();
                }
            });
        }
        else if(result.error){
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: result.error,
                showConfirmButton: true,
                allowOutsideClick: false,
                showCloseButton: true,
                timer: 2000
            });
        }
    }
    async function handleSave(account) {
        const updatedAccount = {
            userName: user.userName,
            lastName: account.lastName || user.lastName,
            name: account.name || user.name,
            dateOfBirth: account.dateOfBirth || user.dateOfBirth,
            country: account.country || user.country || '',
            token: user.token,
            _language: localStorage.getItem("active_language"),
            isProfessor: user.isProfessor,
            isActive: user.isActive

        };
        if (!updatedAccount.country) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: labels.getText()["COUNTRY_REQUIRED"](),
                timer: 5000
            });
        }
        else {
            var result = (await api.sendMessage("updateUser", {
                body: {
                    userAccount: updatedAccount,
                },
                user: user
            }));
            if (result.noCountry && !result.error) {
                withReactContent(Swal).fire({
                    title: <i>{labels.getText()["DONE"]()}</i>,
                    icon: "error",
                    text: labels.getText()["COUNTRY_REQUIRED"](),
                    timer: 5000
                });
            }
            if (result.success) {
                withReactContent(Swal).fire({
                    title: <i>{labels.getText()["DONE"]()}</i>,
                    icon: "success",
                    text: result.message,
                    timer: 5000
                }).then((result) => {
                    if (result.isConfirmed || result.isDismissed) {
                        window.location.reload();
                    }
                });
                if (updatedAccount.name && updatedAccount.lastName && updatedAccount.country) {
                    login(JSON.stringify(updatedAccount));
                }
            }
            else if (result.error) {
                withReactContent(Swal).fire({
                    title: <i>{labels.getText()["ERROR"]()}</i>,
                    icon: "error",
                    text: result.error,
                    showConfirmButton: true,
                    allowOutsideClick: false,
                    showCloseButton: true,
                    timer: 2000
                });
            }
            setIsChanged(false);
            setEmail(user.userName);
            setFirstName(user.firstName);
            setLastName(user.lastName);
            setDateOfBirth(user.dateOfBirth);
            setCountry(user.country);
        }
    }

    function createModalOTP() {
        return (
            <Modal show={showOTPModal} dialogClassName="modal-dialog-centered">
                <Modal.Header closeButton onClick={(e) => { setShowOTPModal(false); return (<Navigate to="/" />); }}>
                    <Modal.Title className='Text700__6'>{labels.getText()["OTP_MODAL_DELETE_TITLE"]()}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body text-center Text500__5">
                        <p>{labels.getText()["OTP_MODAL_BODY"]()}</p>
                        <OtpInput
                            inputType='tel'
                            value={otp}
                            onChange={setOtp}
                            numInputs={5}
                            renderSeparator={<span>-</span>}
                            inputStyle={{
                                width: '2em',
                            }}
                            renderInput={(props) => <input {...props} />}
                        />
                        <br></br>
                        <p>{labels.getText()["OTP_MODAL_FIRST_PART_RESEND"]()}<strong><a href="#" onClick={(e) => { resendDeleteEmail(user.userName) }}>{labels.getText()["OTP_MODAL_SECOND_PART_RESEND"]()}</a></strong>  {labels.getText()["OTP_MODAL_THIRD_PART_RESEND"]()}</p>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className='d-flex justify-between'>
                    <button className="then-small-button" type="button" onClick={(e) => { checkOTP() }}>
                        {labels.getText()["SUBMIT_BUTTON"]()}
                    </button>
                    <button className="then-small-button" type="button" onClick={(e) => { setShowOTPModal(false) }}>
                        {labels.getText()["CLOSE"]()}
                    </button>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
    if (user) {
        return (
            <div className='wrap'>
                {createDeleteAccountModal()}
                {createChangePasswordModal()}
                {SetMenu({ user: user })}
                {createModalOTP()}
                <div className="container py-5 Text500__5">
                    <div className="row g-5">
                        <div className="col-lg-5 wow fadeIn column-sx" data-wow-delay="0.1s">
                            <div className="stack-title">
                                {getBreadcrumb({
                                    page: "user_profile",
                                })}
                                <h1 className="document-boost"> {labels.getText()["ACCOUNT_DETAILS"]()}</h1>
                            </div>

                            <div className="row">
                                <p className="text-start">{labels.getText()["ACCOUNT_DETAILS_DESC"]()}</p>

                                <div>
                                    <div className="mb-3 row Text500__5">
                                        <label htmlFor="email" className="col-sm-4 col-form-label text-start">{labels.getText()["EMAIL_ADDRESS"]()}</label>
                                        <div className="text-start">
                                            <input type="email" className="form-control" id="email" value={email} disabled />
                                            <small id="emailHelp" className="form-text text-muted text-start">{labels.getText()["TO_CHANGE_EMAIL"]()}</small>
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="firstName" className="col-sm-4 col-form-label text-start">{labels.getText()["FIRST_NAME"]()}</label>
                                        <div className="">
                                            <input type="text" className="form-control" id="firstName" value={firstName} onChange={(e) => {
                                                setFirstName(e.target.value);
                                                if (e.target.value !== '' && e.target.value !== user.name) {
                                                    account.name = e.target.value;
                                                }
                                                handleInputChange();
                                            }} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="lastName" className="col-sm-4 col-form-label text-start">{labels.getText()["LAST_NAME"]()}</label>
                                        <div className="">
                                            <input type="text" className="form-control" id="lastName" value={lastName} onChange={(e) => {
                                                setLastName(e.target.value);
                                                if (e.target.value !== '' && e.target.value !== user.lastname) {
                                                    account.lastName = e.target.value;

                                                }
                                                handleInputChange();
                                            }} />
                                        </div>
                                    </div>
                                    {/* {countries && countries.length > 0 && ( */}
                                    <div className='mb-3 row'>
                                        <label htmlFor='country' className='col-sm-4 col-form-label text-start'>{labels.getText()["COUNTRY"]()}</label>
                                        <div className="">
                                            {user.country ? (
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="accountType"
                                                    value={country}
                                                    disabled={user.country ? true : false}
                                                />
                                            ) : (
                                                <Select
                                                    key={refreshKey}
                                                    value={user.country}
                                                    onChange={(e) => {
                                                        account.country = e.value;
                                                        setCountry(e.value);
                                                        handleInputChange();
                                                    }}
                                                    options={countries}
                                                    placeholder={labels.getText()["CHOOSE"]()}
                                                    noOptionsMessage={() => labels.getText()["NO_OPTIONS"]()}
                                                    theme={theme => ({
                                                        ...theme,
                                                        colors: {
                                                            ...theme.colors,
                                                            neutral50: '#555',  // Placeholder color
                                                        },

                                                    })}
                                                    required />
                                            )}


                                        </div>
                                        {!country && (<p className='text-danger mt-2'>{labels.getText()["COUNTRY_REQUIRED"]()}</p>)}
                                    </div>
                                    {/* )} */}
                                    <div className="mb-3 row">
                                        <label htmlFor="dateOfBirth" className="col-sm-4 col-form-label text-start">{labels.getText()["DATE_OF_BIRTH"]()}</label>
                                        <div className="">
                                            <input type="date" className="form-control" id="dateOfBirth" value={dateOfBirth} onChange={(e) => {
                                                setDateOfBirth(e.target.value);
                                                if (e.target.value !== '' && e.target.value !== user.dateOfBirth) {
                                                    account.dateOfBirth = new Date(e.target.value);
                                                }
                                                handleInputChange();
                                            }} max={new Date().toISOString().split('T')[0]} />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <label htmlFor="accountType" className="col-sm-4 col-form-label text-start">{labels.getText()["ACCOUNT_TYPE_LABEL"]()}</label>
                                        <div className="">
                                            <input
                                                type="text"
                                                className="form-control"
                                                id="accountType"
                                                value={labels.getText()["ACCOUNT_TYPE"]({ accountType: accountType })}
                                                disabled
                                            />
                                        </div>
                                    </div>
                                    <div className="mb-3 row">
                                        <div className="col-sm-8 d-flex justify-content-start mt-3 w-100">
                                            <div className="d-flex justify-content-between w-100">
                                                <button type="button" className="then-small-button" onClick={(e) => {
                                                    setAccount(account);
                                                    handleSave(account);
                                                }} disabled={!isChanged}>{labels.getText()["SAVE"]()}</button>
                                                <button type="button" className="then-small-button cancel" onClick={(e) => {
                                                    setIsChanged(false);
                                                    setEmail(user.userName);
                                                    setFirstName(user.firstName);
                                                    setLastName(user.lastName);
                                                    setDateOfBirth(user.dateOfBirth);
                                                    setCountry(user.country || null);
                                                    refreshSelect();
                                                }} disabled={!isChanged}>{labels.getText()["CANCEL"]()}</button>
                                            </div>
                                        </div>
                                        <div className='col-sm-8 d-flex justify-content-start mt-5 w-100'>
                                            <div className="d-flex justify-content-between w-100">
                                                {user.accType == 'local' && (<button type="button" className="then-small-button" onClick={handleShow}>{labels.getText()["CHANGE_PASSWORD"]()}</button>)}
                                                {((user.accType == 'google' || user.accType == 'facebook') && user.isProfessor == undefined && !user.isProfessorRequested) && (<button type="button" onClick={() =>
                                                    withReactContent(Swal).fire({
                                                        title: <i className="Text700__8">{labels.getText()["CONFIRM_ROLE_PROFESSOR"]()}</i>,
                                                        icon: "info",
                                                        html: (
                                                            <div className='Text500__5 d-flex flex-column' style={{ overflowX: 'hidden' }}>
                                                                {labels.getText()["REQ_PROFESSOR"]()}
                                                                <button type="button" className="then-small-button" onClick={handleProfessor}>{labels.getText()["REQUEST"]()}</button>
                                                            </div>
                                                        ),

                                                        showConfirmButton: false,
                                                        allowOutsideClick: true,
                                                        showCloseButton: true,

                                                    })
                                                } className="then-small-button" >{labels.getText()["CONFIRM_ROLE_PROFESSOR"]()}</button>)}
                                                <button type="button" className="then-small-button-danger Text500__5" onClick={handleDeleteShow} >{labels.getText()["DELETE_ACCOUNT"]()}</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ProfileCards />
                    </div>
                </div>
            </div >


        )
    }

    else {
        { SetMenu({ user: user }) }
        return (<Navigate to="/" />);
    }

}

export default User_Profile_Screen;