import { useContext } from 'react';
import UserContext from './User_Context';
import * as labels from "./localized/labels";

function ThenQPackageLibrary(props) {
    const { questionPackages, onCardClick, isDivVisible, toggleDivVisibility, onPackageCreated } = props;
    // const [questionPackages, setQuestionPackages] = useState(null);
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;
    if (questionPackages) { questionPackages.sort((a, b) => new Date(b.generationTime) - new Date(a.generationTime)) }
    const handleCardClick = (card, index) => {
        if (onCardClick) {
            card.index = index;
            onCardClick(card);
            toggleDivVisibility(false);
        }
    };

    if ((!questionPackages)) {
        return (
            <div className="App App-body">
                <h1>{labels.getText()["WAITING_FOR_DATA"]()}</h1>
            </div>
        );
    }
    else {
        return (
            <div className="test-list">
                {questionPackages // Sort by most recent
                    .map((card, index) => (
                        <div
                            key={index}
                            className="card-document  card-service-item d-flex flex-column justify-content-center text-center rounded"
                            onClick={() => card.status !== 'processing' && isDivVisible && handleCardClick(card, questionPackages.length - index)}
                            style={{
                                cursor: card.status === 'processing' || !isDivVisible ? 'default' : 'pointer',
                                padding: '10px',
                                opacity: isDivVisible ? 1 : 0.5,
                                pointerEvents: isDivVisible ? 'auto' : 'none'
                            }}
                        >
                            <div className="list-items-content">
                                <img
                                    className="list-items-content-icon-color service-icon-sm btn-square"
                                    src="/huge-icon/filesandfolder/bulk/quiz.svg"
                                    alt="Icon"
                                />
                                <div className='list-items-content-text'>
                                    <div className="title-number ">
                                        <p className="Text700__5">{labels.getText()["PACKAGE"]()} #{questionPackages.length - index} </p>
                                        <p className="Text400__6 violet500">
                                            <small>({card.questions.length} {labels.getText()["QUESTIONS"]()})</small></p>
                                    </div>

                                    <div className="creation-date">
                                        <span className="Text500__5">
                                            {
                                                labels.getText()["TEST_SMALL_MESSAGE"]
                                                    ({
                                                        generatedDate: new Date(card.generationTime).toLocaleDateString(),
                                                        generatedTime: new Date(card.generationTime).toLocaleTimeString()
                                                    })
                                            }
                                        </span>
                                    </div>
                                    {card.status === 'processing' && (
                                        <div style={{ width: "100%" }}>
                                            <div className="progress" style={{ marginBottom: "2em", height: "3em", width: "75%", marginLeft: "auto", marginRight: "auto", marginTop: "1em" }}>
                                                <div className="progress-bar" role="progressbar" style={{ width: `45%`, backgroundColor: 'var(--Violet__500, #662FE7)' }}></div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    ))
                }
            </div >





        )
    }
}

export default ThenQPackageLibrary;