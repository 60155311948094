import '../App.css';
import { useContext } from "react";
import UserContext from '../components/User_Context';
import { useNavigate, Navigate, useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';
import React, { useState,useEffect } from 'react';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from '@react-oauth/google';
import * as labels from "../components/localized/labels";
import { SetMenu } from '../components/Navigation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook } from '@fortawesome/free-brands-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import { GoogleOAuthProvider } from '@react-oauth/google';
import TagManager from "react-gtm-module";
import { useCookies } from 'react-cookie';


const AutoLogin = () => {
    useEffect(() => {
        const handleLogin = async () => {
            const checkButtonAndClick = () => {
                const button = window.$("#googleBtn").find("[role='button']");

                if (button.length > 0) {
                    var token = "15555";
                    console.log("Redirecting to app...");
                    //window.location.href = `com.deeplinkanna.mobile://login?token=${token}`;
                    console.log("Redirection should happen now...");
                    button.trigger("click");
                } else {
                    setTimeout(checkButtonAndClick, 500);
                }
            };

            checkButtonAndClick(); // Start checking for the button
        };

        handleLogin();
    }, []);

    return null; // No UI since login is triggered automatically
};
function Login_Screen() {
    const navigate = useNavigate();
    const { profileCode, provider, loginId } = useParams();
    const { user, login } = useContext(UserContext);
    const [userName, setUserName] = useState("");
    const [userPassword, setUserPassword] = useState("");
    let oAuthGoogle = process.env.REACT_APP_GOOGLE_OAUTH_CLIENT_ID;
    const [cookiesThirdParty, setThirdPartyCookie] = useCookies(localStorage.getItem("thirdPartyCookies"));


    function doLogin() {
        api.sendMessage("authenticate", {
            body: {
                userName: userName,
                password: userPassword
            }

        }).then((data) => {
            if (data.token) {
                var user = data.user;
                user.token = data.token;
                login(JSON.stringify(user));
                if (profileCode) {
                    navigate(`/checkout/${profileCode}`);
                }
            }
            else {

            }

        })
            .catch((err) => {
                //err);
                alert(`${labels.getText()["WRONG_CREDENTIALS"]()}`);
            });
    }

    function responseMessageGoogle(response) {
        // console.log(response.credential);

        api.sendMessage("googleLogin", {
            body: {
                credential: response.credential,
                loginId: loginId
            }

        }).then((data) => {
            if (data.token) {
                var user = data.user;
                user.token = data.token;
                if (data.newUser) {

                    if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

                        // send custom event to GTM for initiate registration
                        try {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: "InitiateRegistration",
                                    // category: "category",
                                    action: "action",
                                    // label: "label",
                                    // value: "value",
                                },
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                }

                login(JSON.stringify(user));
                if (profileCode) {
                    navigate(`/checkout/${profileCode}`);
                }
            }
            else {

            }

        })
            .catch((err) => {
                alert(labels.getText()["PROBLEM"]());
            });

    }

    function errorMessageGoogle(response) {
        var errorResponse = response;

        alert(
            labels.getText()["GOOGLE_PROBLEM"]()

        )
    }

    function responseFacebook(response) {
        //console.log(response);
        api.sendMessage("facebookLogin", {
            body: {
                accessToken: response.accessToken,
                loginId: loginId
            }

        }).then((data) => {
            if (data.token) {

                var user = data.user;
                user.token = data.token;
                login(JSON.stringify(user));

                if (data.newUser) {

                    if (process.env.REACT_APP_ENEABLE_TRACKING && cookiesThirdParty?.cookieConsent) {

                        // send custom event to GTM for initiate registration
                        try {
                            TagManager.dataLayer({
                                dataLayer: {
                                    event: "InitiateRegistration",
                                    // category: "category",
                                    action: "action",
                                    // label: "label",
                                    // value: "value",
                                },
                            });
                        } catch (err) {
                            console.log(err);
                        }
                    }
                }

                if (profileCode) {
                    navigate(`/checkout/${profileCode}`);
                }
            }
            else {

            }

        })
            .catch((err) => {
                alert(
                    labels.getText()["FACEBOOK_PROBLEM"]()
                );
            });
    }
    if (!user) {
        if (loginId && provider) {
            if (provider === 'facebook') {
                return (<FacebookLogin
                    appId="1092987268420959"
                    cssClass="btnFacebook animated slideInRight "
                    autoLoad={true}
                    fields="name,email,picture"
                    icon={< FontAwesomeIcon icon={faFacebook} style={{ marginLeft: '5px' }}></ FontAwesomeIcon>}
                    // onClick={componentClicked}
                    callback={responseFacebook}
                    textButton={labels.getText()["FACEBOOK_LOGIN"]()}
                />);
            }
            else if (provider === 'google') {
                return (
                    <GoogleOAuthProvider clientId={oAuthGoogle}>
                        <div className='hidden' id='googleBtn'>
                            <GoogleLogin
                                //shape='pill'
                                text='Sign In with Google'
                                theme='filled_blue'
                                onSuccess={responseMessageGoogle}
                                onError={errorMessageGoogle}
                            /></div>
                        <AutoLogin />
                    </GoogleOAuthProvider>
                )

            }
        }
        else {
            return (
                <GoogleOAuthProvider clientId={oAuthGoogle}>

                    <div className='wrap'>

                        {SetMenu({ user: user })}
                        <div className="container py-5">
                            <div className="row g-5">
                                {/* <div className="col-lg-6 wow fadeIn column-sx" data-wow-delay="0.1s">
                                <div className="stack-title">
                                
                                    <h1 className="document-boost"> {labels.getText()["WHO_ARE_YOU"]()}</h1>
                                </div>
    
                                <img src={logo}
                                                className="img-fluid shadow-lg p-3 mb-5 bg-white rounded" alt="Phone image" />
                           
    
                            </div> */}

                            <div className='col-lg-6 column-dx-bg mx-auto'>
                                <div className="row g-4">
                                    <h2 id="loginHeader" className="mb-4">
                                        {labels.getText()["LOGIN_CREDENTIAL"]()}
                                    </h2>
                                    <div className="form-outline mb-4 Text500__5">
                                        <label className="form-label" htmlFor="form1Example13">
                                            {labels.getText()["EMAIL_ADDRESS"]()}
                                        </label>
                                        <input type="email" id="form1Example13" className="form-control form-control-lg Text500__5" onChange={(e) => { setUserName(e.target.value) }} value={userName} />

                                    </div>
                                    <div className="form-outline mb-4 Text500__5">
                                        <label className="form-label" htmlFor="form1Example23">
                                            {labels.getText()["PASSWORD"]()}
                                        </label>
                                        <input type="password" id="form1Example23" className="form-control form-control-lg Text500__5" onChange={(e) => { setUserPassword(e.target.value) }} value={userPassword}
                                            onKeyDown={(e) => {
                                                if (e.key === "Enter") {
                                                    doLogin();
                                                }
                                            }} />

                                    </div>
                                    <div className="d-flex justify-content-around align-items-center mb-4 Text500__5" id="rememberMeLineAndPassword">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" value="" id="form1Example3"
                                                onKeyDown={(e) => {
                                                    if (e.key === "Enter") {
                                                        doLogin();
                                                    }
                                                }} />
                                            <label className="form-check-label Text500__5" htmlFor="form1Example3">
                                                {labels.getText()["REMEMBER_ME"]()}
                                            </label>
                                        </div>
                                        <a href="/passwordReset">
                                            {labels.getText()["FORGOT_PASSWORD"]()}</a>
                                    </div>
                                    <div className="d-flex justify-content-center align-items-center mb-4">
                                        <button
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                textAlign: 'center',
                                            }}
                                            className="then-small-button animated slideInRight"
                                            href="#"
                                            onClick={doLogin}
                                        >{labels.getText()["LOGIN"]()}</button>
                                    </div>

                                    {/* <div className="d-flex justify-content-center align-items-center space-between "> */}

                                    <div className="d-flex flex-md-row justify-content-center align-items-center space-between ">
                                        {/* <div className="mb-3" id='btnGoogle'> */}

                                        <div id='btnGoogle'>
                                            <GoogleLogin
                                                shape='pill'
                                                text='signin'
                                                type='icon'
                                                theme='filled_blue'
                                                width="150"
                                                onSuccess={responseMessageGoogle}
                                                onError={errorMessageGoogle}

                                            />
                                        </div>



                                        <div  >
                                            <FacebookLogin
                                                appId="1092987268420959"
                                                cssClass="then-small-button-facebook"
                                                autoLoad={false}
                                                fields="name,email,picture"
                                                icon={< FontAwesomeIcon icon={faFacebook} ></ FontAwesomeIcon>}
                                                // onClick={componentClicked}
                                                callback={responseFacebook}
                                                // textButton={labels.getText()["FACEBOOK_LOGIN"]()}
                                                textButton={""}

                                            /></div>


                                    </div>
                                </div>

                                </div>
                            </div>
                        </div>
                    </div >
                </GoogleOAuthProvider>

            );
        }


    }
    else {
        { SetMenu({ user: user }) }
        return (<Navigate to="/" />);
    }

}

export default Login_Screen;