import '../App.css';
import React, { useState, useContext, useEffect } from 'react';
import UserContext from '../components/User_Context';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content'
import * as labels from "../components/localized/labels";
import { SetMenu, } from '../components/Navigation';
import FileUpload from '../components/upload/upload';
import { useNavigate, useParams } from 'react-router-dom';
import * as api from '../api/apiConnector';

function Speech_Screen() {
  const navigate = useNavigate();
  const [newBookId, setNewBookId] = useState(null);
  const { user } = useContext(UserContext);
  const _user = JSON.parse(user);
  const [uploadProgress, setUploadProgress] = useState(null);
  const { professorclassroomId, classroomId } = useParams();

  const getUserSubscription = async () => {
    const result = (await api.sendMessage("getUserSubscription", {
      user: _user
    }));
    if (result.success) {
       if(!result.subscriptionProfile.audio) {
        withReactContent(Swal).fire({
          title: labels.getText()["ERROR"](),
          icon: "error",
          text: labels.getText()["PLAN_DOESNT_INCLUDE_VOICE"](),
          showConfirmButton: false,
          allowOutsideClick: false,
          showCloseButton: true
        });
         navigate("/");
       }
    } else {
      navigate("/catalogue");
    }
  }

  useEffect(() => {
    (async () => {
      getUserSubscription();
    })();
  }, []);

  function showUploadProgress() {
    if (uploadProgress) {

      return (
        <div className='container py-5 d-flex flex-column col-12'>
          <label className='pb-2'>
            {labels.getText()["PROGRESS"]()}
          </label>
          <div className="progress" style={{ marginBottom: "2em", height: "3em" }}>
            <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${uploadProgress}%` }}>{`${uploadProgress}%`}</div>
          </div>
        </div>
      );
    }
    else {
      return (null);
    }
  }

  return (
    <div className="App wrap">
      {SetMenu({ user: _user })}
      <div className="container-fluid py-5">

        <div className="container py-2">
          <div className="wow fadeIn" data-wow-delay="0.1s" style={{ visibility: "visible", "animationDelay": "0.1s", "animationName": "fadeIn" }}>
            <p className="mb-4">{
              labels.getText()["AUDIO_EXPLANATION"]({ user: _user })
            }</p>
          </div>
          <hr />
        </div>

        {!uploadProgress ? (<FileUpload fileType="audio/*" onChunkSent={(event) => {
          //console.log(event);
          //update the progress
          setUploadProgress(event.totalChunks > 0 ? (event.chunkNumber * 100 / event.totalChunks).toFixed(0) : 0);
          
          if (event.chunkNumber === event.totalChunks) {
            if (event && event.result && event.result.document) {
              setNewBookId(event.result.document._id);
              //upload completed, close the progress and refresh

              setUploadProgress(null);

              if (professorclassroomId) {
                navigate(`/professor/document/classroom/${event.result.document._id.toString()}/${professorclassroomId}`);
              }
              else if (classroomId) {
                navigate(`/document/classroom/${event.result.document._id.toString()}/${classroomId}`);
              }
              else {
                navigate(`/document/${event.result.document._id.toString()}`);
              }
            
            }
            else {
              //console.log(event.result);
              if (event && event.result && event.result.error) {

                withReactContent(Swal).fire({
                  title: <i>{labels.getText()["ERROR"]()}</i>,
                  icon: "error",
                  text: labels.getText()["GENERIC_ERROR"]({ error: event.result.error }),
                  showConfirmButton: true,
                  allowOutsideClick: false,
                  showCloseButton: true,
                  timer: 10000
                });

                setUploadProgress(null);
              }
            }
          }
        }}></FileUpload>) : (null)}
        {showUploadProgress()}
      </div>
    </div>
  );
}

export default Speech_Screen;