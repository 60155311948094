import React, { useState, useEffect, useCallback } from "react";
import * as labels from "../../localized/labels";
import Recorder from "./Recorder";
import Swal from 'sweetalert2';
import withReactContent from "sweetalert2-react-content";

const AudioUpload = ({ handleCreatePdf, getDuration, transcribeAudio, getFileType }) => {

    const [audioFile, setAudioFile] = useState(null);
    const [fileName, setFileName] = useState(null);
    const [transcription, setTranscription] = useState(null);
    const [showInput, setShowInput] = useState(false);
    const [audioFileUploaded, setAudioFileUploaded] = useState(false);
    const [audioDetails, setAudioDetails] = useState({
        url: null,
        blob: null,
        chunks: null,
        duration: {
            h: 0,
            m: 0,
            s: 0
        }
    });

    const handleUpload = useCallback(async (file) => {
        if (!audioFile && !file ) return;
        const duration = await getDuration(audioFile);

        withReactContent(Swal).fire({
            title: <i>{labels.getText()["AUDIO_FILE_PROGRESS"]()}</i>,
            icon: "info",
            text: ""
        });

        //setIsVoiceProcessing(true);
        var audioDuration = audioDetails.duration.s;
        if (audioDuration === 0 && audioDetails.duration.ms > 0) {
            audioDuration = audioDetails.duration.ms / 1000;
        }
        else if (!audioDetails.url && !audioDuration) {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["AUDIO_FILE_PROGRESS"]()}</i>,
                icon: "info",
                
            });
        }
        if (!audioDetails.url || !audioDuration || audioDuration === 0) {
            audioDuration = duration.s;
        }

        const byteArray = await audioFile.arrayBuffer();
        const fileData = Array.from(new Uint8Array(byteArray));

        try {
            var response = await transcribeAudio(fileData, audioFile.type, audioDuration, audioFile.uploaded);
            
            if (response.success && response.result) {
                withReactContent(Swal).fire({
                    title: <i>{labels.getText()["DONE"]()}</i>,
                    icon: "success",
                    text: response.message
                })
                const text = response.result;
                setTranscription(text);
                //transcriptRef.current = await Promise.resolve(response.result);
                document.getElementById("transcript").innerText = text;
                setShowInput(true);
                setAudioFileUploaded(response.uploaded);

            } else {
                withReactContent(Swal).fire({
                    title: <i>{labels.getText()["ERROR"]()}</i>,
                    icon: "error",
                    text: response.error
                });
                setAudioFile(null);
            }

        } catch (error) {
            console.error(error);
        }
    }, [audioFile]);

    /*
    1) call uploader logic sending chunks
    2) Other logic
    */

    const handleAudioUpload = (file) => {
        setAudioFile(file);
    };

    const handleFileChange = (event) => {
        event.preventDefault();
        const audioFile = event.dataTransfer ? event.dataTransfer.files[0] : event.target.files[0];
        const type = getFileType(audioFile);
        if(type === 'audio' || type === 'video') {
            setAudioFile(audioFile);
            setFileName(audioFile.name);
            audioFile.uploaded=true;
        } else {
            withReactContent(Swal).fire({
                title: <i>{labels.getText()["ERROR"]()}</i>,
                icon: "error",
                text: labels.getText()["FILE_TYPE_NOT_SUPPORTED"]()
            })
        }
    };

    const handleAudioStop = (data) => {
        setAudioDetails(data);
    };

    useEffect(() => {
        if (audioFile) {
            handleUpload(audioFile, fileName);
        }
    }, [audioFile, handleUpload, fileName]);

    return (
        <>
            <div className="container">
                <div hidden={showInput}
                    className="container voice-container"
                    onDrop={handleFileChange}
                >
                    <input id="input-audio-upload" accept="audio/*" type="file" className="inputFile" onChange={handleFileChange} />
                    <Recorder
                        record={true}
                        audioURL={audioDetails.url}
                        showUIAudio
                        fileName={fileName ?? ""}
                        audioFile={audioFile}
                        handleAudioUpload={handleAudioUpload}
                        handleAudioStop={handleAudioStop}
                        mimeTypeToUseWhenRecording={`audio/webm`}
                    />
                </div>
                <div className="container" hidden={!showInput}>
                {/* Removed contentEditable and using textarea */}
                <textarea 
                    className="form-control" 
                    id="transcript"
                    value={transcription} // Assuming you manage this state elsewhere
                    onChange={(e) => setTranscription(e.target.value)} // Set transcript state
                    style={{ width: '100%', height: '300px', textAlign: "left" }} 
                />
                <button className="then-small-button-ghost margin openButton mt-2" onClick={() => handleCreatePdf(transcription, fileName, true, audioFileUploaded)}>
                    OK
                </button>
                </div>
            </div>
        </>
    );
};

export default AudioUpload;
