import React, {useState} from "react";
import * as labels from '../../localized/labels';
import ImageSlider from './ImageSlider';
import * as api from '../../../api/apiConnector';
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import UserContext from '../../User_Context';
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

const  ImageUpload = ({handleFileUpload, getRandomFileName}) => {
    //for images
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [images, setImages] = useState([]);
    const [imageFiles, setImageFiles] = useState([]);
    const navigate = useNavigate();
    let { user } = useContext(UserContext);
    user = user ? JSON.parse(user) : null;

    const [isOcrProcessing, setIsOcrProcessing] = useState(false);
    let currentId = currentImageIndex;

    function isFileImage(file) {
        return file && file['type'].split('/')[0] === 'image';
    }
    //Image carousel functions
    const previousSlide = () => {
        const lastIndex = images.length - 1;
        const shouldResetIndex = currentImageIndex === 0;
        const index = shouldResetIndex ? lastIndex : currentImageIndex - 1;
        setCurrentImageIndex(index);
    };

    const nextSlide = () => {
        const lastIndex = images.length - 1;
        const shouldResetIndex = currentImageIndex === lastIndex;
        const index = shouldResetIndex ? 0 : currentImageIndex + 1;
        setCurrentImageIndex(index);
    };

    const handleImageFile = (event) => {
        const files = event.dataTransfer ? 
            Array.from(event.dataTransfer.files) :
            Array.from(event.target.files);
        
        files.forEach(async file => {
            if(isFileImage(file)){ //checking just in case one of multiple is not img
                const imgUrl = URL.createObjectURL(file);
                setImageFiles((imageFiles) => [file, ...imageFiles]);
                setImages((images) => [imgUrl, ...images]);
            }
        });

    }

    const textToPdf = async (wholeText) => {
        const result = await api.sendMessage('createPdf', {
            body: {
                transcribedText: wholeText
            },
            user: user
        });
        if (result.success) {
            setImages([]);
            setImageFiles([]);
            setIsOcrProcessing(false);
            navigate(`/document/${result.document._id}`);
        }
    }

    const extractTextFromImages = async (imageFiles) => {
        setIsOcrProcessing(true);

        if(imageFiles.length > 1){
            //send file one by one receiving text
            await Promise.all(imageFiles.map(async (imageFile, index) => {
                const originalname = imageFile.name;
                const byteArray = await (imageFile).arrayBuffer();
                const fileData = Array.from(new Uint8Array(byteArray));
                var result = await api.sendMessage('transcribeImage', {
                    body: {
                        image: fileData,//imageFiles[i], //mimeType is inside the file,
                        mimeType: imageFile.type,
                        originalname: originalname ?? getRandomFileName()
                    },
                    user: user
                });
            
                if(result.success){
                    const text = result.result; // create a separate variable for each iteration
                    await textToPdf(text); // pass the separate variable to the function
                } else {
                    setIsOcrProcessing(false);
                    setImageFiles([]);
                    setImages([]);
                    withReactContent(Swal).fire({
                        title: <i>{labels.getText()["UNABLE_TO_TRANSCRIBE"]()}</i>,
                        icon: 'warning',
                        confirmButtonText: 'Ok'
                    })
                }
            }));
        } else if (imageFiles.length === 1) {
            handleFileUpload(imageFiles[0]);
        }
    };

    const createPdf = () => {
        extractTextFromImages(imageFiles);
    }

    return (
        <>
        { !isOcrProcessing && (
        <div className="mx-auto">
            <button className="then-small-button-ghost margin openButton m-2" onClick={() => {
                    window.$("#input-camera-upload").trigger("click");}}>
                <i style={{ fontSize: "1em" }} className="fa fa-camera fa-2x"></i>
            </button>
            <button className="then-small-button-ghost margin openButton m-2" onClick={() => {
                    window.$("#input-image-upload").trigger("click");
                    }}>
                <i style={{ fontSize: "1em" }} className="fas fa-images"></i>
            </button>
        </div> )}
        <input id="input-camera-upload" accept="image/*" capture="environment" type="file" className="inputFile" onChange={handleImageFile} />
            <input id="input-image-upload" className="inputFile" type="file" accept="image/*" multiple onChange={handleImageFile} />
            
        <div
            style={{position : "relative", paddingTop : "1em", paddingBottom: "0"}}
            className="drag-zone container"
            onDrop={handleImageFile}
            >
            {images.length === 0 && (
                <div className="d-flex flex-column justify-content-center text-center rounded dropzone-text pb-4 hiddenMobile">
                    <p>{labels.getText()["DRAG_DROP_FILES"]()}</p>
                </div>
            )}
            {images.length > 1 && (
                <div className={`col slide-arrow back`} onClick={previousSlide}>
                    ❮
                </div>
            )}
            {images.length > 0 && (
                <ImageSlider url={images[currentId]}/>
            )}
            {images.length > 1 && (
                <div className={`col slide-arrow forth`} onClick={nextSlide}>
                    ❯
                </div>
            )}
            { !isOcrProcessing && imageFiles.length > 0 && (
                <button className="then-small-button-ghost margin openButton mt-2" onClick={createPdf}>
                    OK
                </button>
            )}
            {
                isOcrProcessing && (<div><h6>{labels.getText()["LOADING"]()}</h6></div>)
            }
        
        </div>
        </>
    );
};

export default ImageUpload
