import * as labels_it from "./labels_it";
import * as labels_en from "./labels_en";
import * as labels_lt from "./labels_lt";
import * as labels_es from "./labels_es";
import {React} from "react";

const urls = {
    catalogue: "/catalogue",
    account: "/user_profile",
    logout: "/logout",
    student_classrooms: "/classrooms",
    professor_classrooms: "/professor/classrooms",
    language_selector: `<button type="button" class="btn language-button Text500__5" data-toggle="modal" data-target="#languageModal" onClick="$('#languageModal').modal('show')">
                    ##LANGUAGE##
                </button>`,
    not_confirmed_professor_landing: "/not_confirmed_professor"
};

const getLanguage = () => {
    return localStorage.getItem("active_language") || "en";
};
const getText = (label, params) => {

    const _getLocal = () => {
        switch (getLanguage()) {
            case "en":
                {
                    return labels_en;
                }
                break;
            case "it":
                {
                    return labels_it;
                }
                break;
            case "lt":
                {
                    return labels_lt;
                }
                break;
                case "es":
                {
                    return labels_es;
                }
                break;
        }
    };

    return _getLocal().getText(label, params);
}

export { getText, getLanguage, urls };